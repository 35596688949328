import { SecretarioRegionalStatus,MagistradoSalaRegionalStatus,MagistradoSalaSuperiorStatus,ActuarioStatus ,SecretarioSuperiorStatus} from '../statusInterface';

export class Todo
{
    id: string;
    crtd: string; // "2020-06-06 17:14:20"
    date: string; // "2020-06-01 00:00:00"
    detd: string; //null
    docu: string; //"https://https://enlinea.trijaem.ml/notificaciones/acuerdos/U75ZQUdN8e3JFHNOcaqengYImDJfZH3P60nmazO7.pdf"
    empl: string; //52
    empldata: Object; //{data: {nume: 52, conf: "", depa: 1, trab: 20, usua: 103, crtd: "2020-06-03 16:12:29",…}}
    expe: string; //"2/2020"
    nume: string; //1
    ref: string; //"CIJ-20200606-VBMF0"
    tipo: string;
    une: string; //""
    uptd: string; //"2020-06-06 17:14:20"

    actr: string;//2
    cons: string;//"Acuerdo Oficioso"
    depa: string;//6
    mate: string;//""
    nomb: string;//"qeq"
    noti: any;//[{nume: 145, tipo: "acuerdo", fisi: 0, estr: 1, crtd: "2020-08-22 19:22:44"}]
    pate: string;//""
    publ: string; //"2020-08-24T05:00:00.000000Z"
    stat: any; //"notificado"
    tpac: string; //19
    tpju: string; //8
    tppa: string; //16
    tpse: string; //"16,104"
    ag_nume: string;
    sentido_acuerdo: number;
    subsentido_acuerdo: number;
    ciuddata: any;
    conSuspension:boolean;
    razon_correcion:any;
    docu_original:string;
    signdoc: any[]=[];

    /**
     * Constructor
     *
     * @param todo
     */
    constructor(todo)
    {
        {   
            //console.log("todo selected api", todo);
            this.id   = todo.nume;
            this.crtd = todo.crtd;
            this.date = todo.date;
            this.detd = todo.detd;
            this.docu = todo.docu;
            this.docu_original = todo.docu_original;
            this.empl = todo.empl;
            this.empldata = todo.empldata;
            this.expe = todo.expe;
            this.nume = todo.nume;
            this.ref = todo.ref;
            this.tipo = todo.tipo;
            this.une = todo.une;
            this.uptd = todo.uptd;
            this.actr = todo.actr;
            this.cons = todo.cons;
            this.depa = todo.depa;
            this.mate = todo.mate;
            this.nomb = todo.nomb;
            this.noti = todo.noti;
            this.pate = todo.pate;
            this.publ = todo.publ;
            this.stat = todo.stat;
            this.tpac = todo.tpac;
            this.tpju = todo.tpju;
            this.tppa = todo.tppa;
            this.tpse = todo.tpse;
            this.ag_nume = todo.ag_nume;
            this.sentido_acuerdo = todo.sentido_acuerdo;
            this.subsentido_acuerdo = todo.subsentido_acuerdo;
            this.ciuddata= todo.ciuddata;
            this.conSuspension= todo.conSuspension;
            this.razon_correcion = todo.razon_correcion;
            this.signdoc = todo.signdoc;


            const user = JSON.parse(sessionStorage.getItem('usr'));
            const empleado = JSON.parse(sessionStorage.getItem('em'));
            console.log('debug user todo model',user,sessionStorage);

            if(sessionStorage.getItem('tyem').includes('Secretario de acuerdos sala regional')){
                this.stat=new SecretarioRegionalStatus().setStatus(todo.stat);
            } 


            if(sessionStorage.getItem('tyem').includes('Magistrado')){
             this.stat=new MagistradoSalaRegionalStatus().setStatus(todo.stat);
            } 

            if(sessionStorage.getItem('tyem').includes('Actuario')){
              this.stat=new ActuarioStatus().setStatus(todo.stat);
            } 


        }
    }

}
