import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id      : 'notication',
        title   : 'NOTIFICACIONES',
        type    : 'group',
        icon    : 'contact_mail',
        children: [
            {
                id      : 'admin-notication',
                title   : 'Bandeja de Notificaciones',
                type    : 'collapsable',
                icon    : 'mail',
                url     : 'notifications/mail'
            }
        ]
    }
]
