export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': '',
            'SAMPLE'        : {
                'TITLE': 'DASHBOARD',
                'BADGE': '25'
            }
        }
    }
};
