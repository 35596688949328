<!-- BOARDS -->
<div id="boards" class="texture-background" fxLayout="column" fxLayoutAlign="start center" fxFlex fusePerfectScrollbar 
>

<!-- [ngClass]="{
    'texture-background': currentUser.tipo === 'ciudadano' ||  currentUser.tipo === 'autoridad',
    'texture-background-employee': currentUser.tipo === 'empleado',
    'texture-background-asesor': typeUserName === 'Asesor ciudadano',
    'texture-background-actuario': typeUserName === 'Actuario'
  }" -->

<!--   <div class="half-circle-top"></div>
 -->
 <div class="back-1"></div>

    <div class="header" fxLayout="row" fxLayoutAlign="center center" fxFlex="0 0 auto" [@animate]="{value:'*',params:{delay:'100ms'}}">
        <div class="logo-tri" [@animate]="{value:'*',params:{scale:'0.5'}}">
            <img width="130" src="assets/images/logos/logogro.png">
        </div>

        <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <h1 class="logo-header"><span class="name_title">e</span>.Justicia Administrativa Durango</h1>
        </div>
    </div>

    <!-- BOARD LIST -->
    <div class="board-list " fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" [@animateStagger]="{value:'50'}">

        <!-- BOARD -->
        <div class="board-list-item {{board.id}}" *ngFor="let board of boards" (click)="navigateTo(board)" fxLayout="column" fxLayoutAlign="center center" [@animate]="{value:'*',params:{y:'100%'}}" 
        [ngClass]="{
            'background-citizen':  typeUserName === 'Asesor ciudadano' || currentUser.tipo === 'perito' || currentUser.tipo === 'ciudadano' || currentUser.tipo === 'autoridad' || currentUser.tipo === 'empleado' || typeUserName === 'Actuario'
          }">
            <mat-icon class="s-56">{{board.icon}}</mat-icon>
            <div class="board-name">{{board.name}}</div>
        </div>
        <!-- / BOARD -->

    </div>
    <!-- / BOARD LIST -->
    <div class="back-2"></div>
</div>
<!--     <div class="half-circle-bottom"></div>
 --><!-- / BOARDS -->