
<mat-selection-list  #parte (selectionChange)="onItemSelect($event)">

    <div mat-subheader>Actores</div>
    <mat-list-option [value]="part" *ngFor="let part of actores" >
      <div mat-line>    {{part.persona.fullname}}</div>
    </mat-list-option>
    <mat-divider></mat-divider>
    <div mat-subheader>Demandados</div>
    <mat-list-option [value]="part" *ngFor="let part of autoridadesDemandadas">
      <div mat-line>   {{part.persona.fullname}}</div>
    </mat-list-option>
    <mat-divider></mat-divider>
    
    <div *ngIf="terceros.length>0"  mat-subheader>Terceros</div>
    <mat-list-option [value]="part" *ngFor="let part of terceros">
      <div mat-line>   {{part.persona.fullname}}</div>
    </mat-list-option>
    <mat-divider *ngIf="terceros.length>0"></mat-divider>



    <div  *ngFor="let actor of actores">

      <div *ngIf="actor.representantes" mat-subheader>Representantes de {{actor.persona.fullname}}</div>

      <mat-list-option [value]="part" *ngFor="let part of actor.representantes"> 
             {{part.persona.fullname}} {{part.caracter==='representanteLegal'?' Defensor de oficio ':' Representante Autorizado '}}
     </mat-list-option>

      
      <mat-divider *ngIf="actor.representantes"></mat-divider>

    </div>


    <div  *ngFor="let actor of autoridadesDemandadas">

      <div *ngIf="actor.representantes" mat-subheader>Representantes de {{actor.persona.fullname}}</div>

      <mat-list-option [value]="part" *ngFor="let part of actor.representantes"> 
             {{part.persona.fullname}} {{part.caracter==='representanteLegal'?' Defensor de oficio ':' Representante Autorizado '}}
     </mat-list-option>

      
      <mat-divider *ngIf="actor.representantes"></mat-divider>

    </div>

    <div  *ngFor="let actor of terceros">

      <div *ngIf="actor.representantes" mat-subheader>Representantes de {{actor.persona.fullname}}</div>

      <mat-list-option [value]="part" *ngFor="let part of actor.representantes"> 
             {{part.persona.fullname}} {{part.caracter==='representanteLegal'?' Defensor de oficio ':' Representante Autorizado '}}
     </mat-list-option>

      
      <mat-divider *ngIf="actor.representantes"></mat-divider>

    </div>

 </mat-selection-list>