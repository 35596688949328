import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { fuseAnimations } from '@fuse/animations';
import { AppConfigService } from 'app/providers/data/app-config.service';
import { Router } from '@angular/router';
import { adminMenu, actuaryMenuDashboard,citizenMenu, streamMenu, secretaryMenu, authorityMenu, asesorMenu, magistradoMenu, proyectistaMenu, peritoMenu } from "../../providers/data/menu/menu-data";
import { GuidedTour, Orientation, GuidedTourService } from 'ngx-guided-tour';
import { UsersProvider } from 'app/providers/users.provider';
import { tourSecretary, tourCitizen, tourActuary, tourMagistrado, tourAsesor, tourAuthority } from './tour-data';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MailComposeDialogComponent } from '../notifications/mail/dialogs/compose/compose.component';
import { InformationModalComponent } from './information-modal/information-modal.component'
import * as CryptoJS from 'crypto-js';

@Component({
    selector   : 'sample',
    templateUrl: './sample.component.html',
    styleUrls  : ['./sample.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations : fuseAnimations

})
export class SampleComponent implements OnInit
{
    boards: any[] = [];

    currentUser:any;
    typeEmployee:any;
    dialogRef: any;

    title = 'ngx-guided-tour-demo';
    typeUserName = '';

    public dashboardTour: GuidedTour = {
        tourId: 'purchases-tour',
        skipCallback: (stepSkippedOn: number) => this.tutorialComplete(),
        completeCallback: () => this.tutorialComplete(),
        steps: []
    }
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _usersService: UsersProvider,
        private _settings: AppConfigService,
        private guidedTourService: GuidedTourService,
        private _router: Router,
        private _matSnackBar: MatSnackBar,
        public _matDialog: MatDialog,
    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        this.currentUser = this._settings.getCurrentUser();
        this.typeUserName = this._settings.getNameTypeUser();
        this.typeEmployee  = this.currentUser ? this.currentUser.tipo : undefined;
    }

    ngOnInit(){
        if(this.typeEmployee !== undefined) {
            if(this.typeEmployee === 'empleado') {
                this.typeEmployee = this._settings.getTypeEmployee();
            }
            this.boards = this.getBoard(this.typeEmployee);
            this.getTutorialData(this.typeEmployee);
            if(!this.currentUser.tuto) {
                setTimeout(() => {
                    this.guidedTourService.startTour(this.dashboardTour);
                }, 800);
            }else {
                //this.openModalInformation();
            }
        }else {
            console.log('not user')
            this._router.navigate(['/']);
        }
    }

    getBoard(typeUser){
        let menu: any = [];
        switch (typeUser) {
            case 'superadmin':
                menu = (adminMenu);
                break;
            case 'Actuario':
                menu = (actuaryMenuDashboard);
                break;
            case 'Secretario de acuerdos sala regional':
                menu = (secretaryMenu);
                break;
            case 'Asesor ciudadano':
                menu = (asesorMenu);
                break;
            case 'Magistrado':
                menu = (magistradoMenu);
                break;
            case 'ciudadano':
                menu = (citizenMenu);
                break;
            case 'perito':
                menu = (peritoMenu);
                break;
            case 'autoridad':
                menu = (streamMenu);
                break;
            case 'Secretario de estudio y cuenta':
                menu = (proyectistaMenu);
                break;
        }
        const options = menu[0].children.reduce((acc, currenItem, index) => {
            if(index > 0){
                if(currenItem.hasOwnProperty('children')){
                    /*let item = 0;
                    if(typeUser === 'autoridad' && currenItem.id === 'manager-promotion') {
                        item = 1;
                    }
                    const moreOptions =  {
                        name: currenItem.children[item].title, 
                        path: currenItem.children[item].url, 
                        icon: currenItem.children[item].icon
                    };
                    return [...acc, moreOptions]*/
                    const moreOptions = currenItem.children.map(option => {
                        return {
                            id:   option.id,
                            name: option.title, 
                            path: option.url, 
                            icon: option.icon
                        }
                    })
                    return [...acc, ...moreOptions]
                }else {
                    return [...acc,{
                        id:   currenItem.id,
                        name: currenItem.title, 
                        path: currenItem.url, 
                        icon: currenItem.icon
                    }] 
                }
            }
            return []
		}, []);
		console.log(options);
        return options;
    }
    
    getTutorialData(typeUser){
        let tour: any = [];
        switch (typeUser) {
            case 'superadmin':
                tour = (tourSecretary);
                break;
            case 'Actuario':
                tour = (tourActuary);
                break;
            case 'Secretario de acuerdos sala regional':
                tour = (tourSecretary);
                break;
            case 'Asesor ciudadano':
                tour = (tourAsesor);
                break;
            case 'Magistrado':
                tour = (tourMagistrado);
                break;
            case 'ciudadano':
                tour = (tourCitizen);
                break;
            case 'autoridad':
                tour = (tourAuthority);
                break;
        }
        this.dashboardTour.steps = tour;
    }

    tutorialComplete() {
       //this.openModalInformation();
        this._usersService.setTutorialComplete(this.currentUser.nume).subscribe(
            (result) => {
                const save_user = this._settings.toEncrypt(JSON.stringify(result['data']));
                console.log('usr => ', save_user.toString());
                sessionStorage.setItem('usr', save_user.toString());
            },
            (error) => console.error(error)
        );
    }

    navigateTo(board){
        if(board.id === 'electronica') {

            
            this.composeDialog('boletintradicional');
        }else if ( board.id === 'fisica' ){
            this.composeDialog('fisicatradicional');
        }else {
            this._router.navigate([board.path])
        }

    }

    composeDialog(typeNotification): void
    {
        
        this.dialogRef = this._matDialog.open(MailComposeDialogComponent, {
            panelClass: 'mail-compose-dialog',
            data      : {
                typeNotification: typeNotification
            }
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if ( !response )
                {
                    return;
                }
                const actionType: string = response[0];
                const messageApi: string = response[1];
                switch ( actionType )
                {
                    /**
                     * Send
                     */
                    case 'send':
                        // Show the success message
                        this._matSnackBar.open(messageApi, 'OK', {
                            verticalPosition: 'top',
                            horizontalPosition: 'right',
                            duration        : 3000
                        });
                        break;
                    /**
                     * Delete
                     */
                    case 'delete':
                        break;
                }
            });
    }

    openModalInformation(){
        this.dialogRef = this._matDialog.open(InformationModalComponent, {panelClass: ''});
    }
}
