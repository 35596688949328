import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";
import { AppConfigService } from './data/app-config.service';
import { Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class ResourcesProvider {
    private urlApi = '';
    private ruta = '';
    constructor(private httpClient: HttpClient, private settings: AppConfigService, private _router: Router) {
        this.urlApi = this.settings.getUrlApi();
        this.ruta = this._router.url.split("/", 3)[2];
    }

    getJobs(){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'jobs', httpOptions);
    }

    getEntities(){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'entities', httpOptions);
    }

    getDirections(){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'directions', httpOptions);
    }
    
    getDepartments(){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'departments', httpOptions);
    }

    getMunicipalities(){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'advisories/municipalities', httpOptions);
    }

    getMunicipalitiesRegister(){
        const tokenGeneric = 'eyJhdWQiOiIxIiwianRpIjoiNmE4YmQwZDUxNzViNmI4ZGJlZWM5NDk1ODllZjNmZWFmNDQ2OTNjMWRkNTA5ZTIwMDNlNzA1NDVmYmMxMWQ0ZTI3NjNjN2FmNDU3NzgwNWQiLCJpYXQiOjE2MDUwNjAzODYsIm5iZiI6MTYwNTA2MDM4NiwiZXhwIjoxNjM2NTk2Mzg2LCJzdWIiOiIxNDIiLCJzY29wZXMiOltdfQ';
        sessionStorage.setItem('tk', tokenGeneric);
        const httpOptions = this.settings.getHeadersJson();

        return this.httpClient.get(this.urlApi + 'advisories/municipalities', httpOptions);
    }

    getNamesRegister(id_municipio, tipo){
        const httpOptions = {
            headers: new HttpHeaders()
                        .set('Content-Type', 'application/json' )
        };
        return this.httpClient.get(this.urlApi + `entities?muni=${id_municipio}&tipo=${tipo}`, httpOptions);
    }

    getAuthoritieById(id_authoritie){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'authorities/' + id_authoritie, httpOptions);
    }

    getDesignersByDepartment(depa){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi+'employees/'+depa+'/designers', httpOptions);
    }

    getUserByExpediente(params){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + `registrations?stat=aceptado&folio=${params.folio}&depa=${params.depa}`)
    }

    getDocByUrl(url){
        const httpOptions = this.settings.getHeadersBlob(); 
        return this.httpClient.get(url, httpOptions)
    }

    getAgreementByExpediente(params){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'agreements?une='+params+'&tipo=normal', httpOptions);
    }


    getCaracterReconocido(type) {
        const types = [
                {value:"parte", label: "Actor"},
                {value:"tercero", label: "Tercero perjudicado"},
                //{value:"apoderado legal", label: "Apoderado legal"},
                {value:"autorizado", label: "Representante autorizado"},
                {value:"representante legal", label: "Representante legal"}, 
                {value:"autoridad", label: "Autoridad demandada"}        
            ]
        const typeFound = types.find(t => t.value === type);
        if(typeFound !== undefined) {
            return  typeFound.label;
        }

        return type;
    }

    getCaracterReconocidoByType(to = 'promotions') {
        let actor

        if(this.settings.getCurrentUser().tipo === 'perito'){
            let caracter = [
                {value:"perito tercero", label: " Perito del Tercero perjudicado"},
                {value:"perito autoridad demandada", label: "Perito de la Autoridad demandada"},
                {value:"perito tercero discordia", label: "Perito Tercero en discordia"},
                {value:"perito actor", label: "Perito del Actor"},
                ]
            return caracter;    
        }else if(this.settings.getTypeEmployee() == 'Asesor ciudadano'){
            let caracter = [
                {value: 'representante autorizado actora', label: 'Representante legal'},
                {value: 'representante autorizado tercero', label: 'Representante autorizado'}
            ]
            return caracter;
        }else if(this.settings.getCurrentUser().tipo == 'autoridad'){
            let caracter = [
                {promo: 'nulidad', to_user:'', value:"demandado", label: "Demandado"},
                {promo: 'lesividad', to_user:'autoridad', value: 'actor', label: 'Actor'},
                {promo: 'lesividad administrativa grave',to_user:'', value: 'actor', label: 'Actor (Autoridad Substanciadora)'},

                {promo: 'nulidad lesividad administrativa grave', to_user:'autoridad', value:"tercero", label: "Tercero perjudicado"},
                {promo: 'administrativa nograbe', to_user:'', value:'recurrente', label: "Recurrente (Autoridad Investigadora)"},
                {promo: 'nulidad lesividad administrativa grave nograbe', to_user:'autoridad', value:"representante legal", label: "Representante legal"},
                {promo: 'nulidad lesividad administrativa grave nograbe', to_user:'autoridad', value:"representante autorizado", label: "Representante autorizado"}
            ];
        
            return caracter;
        }else{
            let caracter = [
                // {value:"parte", label: "Actor"},
                {value:"parte", label: actor=(this.settings.getCurrentUser().tipo === 'autoridad')? "Actor (juicio de lesividad)":"Actor"},
                {value:"recurrente", label: "Recurrente (Denunciante)"},
                {value:"demandado", label: "Demandado"},
                {value:"tercero", label: "Tercero perjudicado"},
                //{value:"apoderado legal", label: "Apoderado legal"},
                {value:"representante legal", label: "Representante legal"},
                {value:"representante autorizado", label: "Representante autorizado"},
            ]
            if(this.settings.getCurrentUser().tipo === 'autoridad') {
                //caracter.splice(1,1);
                caracter = [...caracter, {value:"autoridad", label: "Autoridad demandada"}, ].reverse()
            }else if(to === 'solicitud'){
                caracter = [...caracter, {value:"autorizado", label: "Representante autorizado"} ]
            }
            return caracter;
        }
        
    }

    capitalize_Words(str){
        return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    }
}

export const TypeCaracter = [
    //{value:"parte", to_user:"autoridad", label: "Actor (juicio de lesividad)"},
    {value: 'actor', label:'Actor'},
    {value:"parte", label: "Actor (juicio de lesividad)"},
    {value:"recurrente", label: "Recurrente (Denunciante)"},
    {value:"demandado", label: "Demandado"},
    {value:"tercero", label: "Tercero perjudicado"},
    //{value:"apoderado legal", label: "Apoderado legal"},
    {value:"representante legal", label: "Representante legal"},
    {value:"representante autorizado", label: "Representante autorizado"},
]

export const TypeFiltersAgreements = [
    {handle: 'pendiente', name:'En revisión', icon: 'history', class:'orange-fg'},
    {handle: 'corregir', name:'En corrección', icon: 'history', class:'red-fg'},
    {handle: 'firmado', name:'Pendiente por firmar', icon: 'history', class:'purple-fg'},
    {handle: 'completo', name:'Firmado/acordado', icon: 'history', class:'blue-fg'},
    {handle: 'notificado', name:'Notificado', icon: 'history', class:'green-fg'},

]

export const TypeFiltersAgreementsMag = [
    {handle: 'pendiente', name:'Pendiente', icon: 'history', class:'orange-fg'},
    {handle: 'corregir', name:'En corrección', icon: 'history', class:'red-fg'},
    {handle: 'corregido', name:'Corregido', icon: 'history', class:'teal-fg'},
    {handle: 'firmado', name:'Firmado', icon: 'check_circle_outline', class:'purple-fg'},
    {handle: 'completo', name:'Firmado/acordado', icon: 'check_circle_outline', class:'blue-fg'},
    {handle: 'notificado', name:'Notificado', icon: 'check_circle_outline', class:'green-fg'},


]

export const TypeChildProceduresSentence = [
    {nume: 1,handle:'fiscal',name: 'Fiscal'},
    {nume: 2,handle:'administrativo', name: 'Administrativo'},
    {nume: 3,handle:'responsabilidadpatrimonial', name: 'Juicio de responsabilidad patrimonial'},
    {nume: 4,handle:'procedimientosparaprocesales', name: 'Procedimientos paraprocesales'},
    {nume: 5,handle:'lesividad', name: 'Lesividad'},
    {nume: 6,handle:'grave', name: 'Responsabilidad administrativa grave'},
    {nume: 7,handle:'recursos', name: 'Recursos'},
    {nume: 8,handle:'incidentes', name: 'Incidentes'},
   /*  {nume: 3, name: 'Juicio de responsabilidad patrimonial'},
    {nume: 4, name: 'Procedimientos paraprocesales'},
    {nume: 5, name: 'Lesividad'},
    {nume: 6, name: 'Responsabilidad administrativa grave'}, */
]

export const TypeChildResourceSentence = [
    {nume: 1, name: 'Recurso de revisión'},
    {nume: 2, name: 'Recurso de apelación'},
    {nume: 3, name: 'Recurso de inconformidad'},
    {nume: 4, name: 'Recurso de queja'},
    /*{nume: 5, name: 'Recurso de revocación'},*/   
    {nume: 6, name: 'Recurso de reclamación'},

]

export const TypeChildIncidentSentence = [
    {nume: 1, name: 'Incidente de acumulación de autos'},
    {nume: 2, name: 'Incidente de falsedad de documento'},
    {nume: 3, name: 'Incidente de incompetencia en razón de territorio'},
    {nume: 4, name: 'Incidente de nulidad de notificaciones'},
    {nume: 5, name: 'Incidente de recusación por causa de impedimento'},
    {nume: 6, name: 'Incidente de incumplimiento de suspensión'},
    {nume: 7, name: 'Incidente innominado'},
    {nume: 8, name: 'Incidente de medidas cautelares'},
  
]

export const TypeJudgSentence = [
    {nume:1, handle: 'nulidad', name: 'Nulidad/lesividad'},
    {nume:2, handle: 'grave', name:'Responsabilidad administrativa grave'}
]
////////////

export const TypeSentence = [
    {handle: 'int', name: 'Interlocutoria'},
    {handle: 'def', name: 'Definitiva'}
]

export const TypeSentidoSentence = [
    
    //grave
    {nume: 1, to:'grave', type:'na' , name: 'Devolución falta administrativa no grave'},
    //end grave
        //interlocutorias
        {nume: 2, to:'nulidad grave', type:'int' , name: 'Confirma'},
        {nume: 3, to:'nulidad grave', type:'int' , name: 'Modifica'},
        {nume: 4, to:'nulidad grave', type:'int' , name: 'Revoca'},
        //end interlocutorias
    {nume: 5, to:'nulidad grave', type:'def int', name: 'Sobreseimiento'},
     //grave
    {nume: 6, to:'grave', type:'na' , name: 'Con responsabilidad'},
    {nume: 7, to:'grave', type:'na' , name: 'Sin responsabilidad'},
     //end grave
        //nulidad/lesividad
     {nume: 8, to:'nulidad grave', type:'def' , name: 'Nulidad e invalidez'},
     {nume: 9, to:'nulidad grave',type:'def' , name: 'Validez'},
        //end nulidad/lesividad
    //interlocutorias
    {nume: 10, to:'nulidad grave', type:'int' , name: 'Procedente'},
    {nume: 11, to:'nulidad grave', type:'int' , name: 'Improcedente'},
    //end interlocutorias

    {nume: 12, to:'nulidad grave', type:'def' , name: 'Se condena'},
    {nume: 13, to:'nulidad grave', type:'def' , name: 'Se absuelve'},
     {nume: 14, to:'nulidad grave', type:'def' , name: 'Se sanciona'},
]

export const TypoSentidoSentenceSuperior = [
    {nume: 2, to:'nulidad grave', type:'def' , name: 'Confirma'},
    {nume: 4, to:'nulidad grave', type:'def' , name: 'Revoca'},
    {nume: 3, to:'nulidad grave', type:'def' , name: 'Modifica'},
    {nume: 5, to:'nulidad grave', type:'def' , name: 'Sobresee '},
    {nume: 10, to:'nulidad grave', type:'def' , name: 'Procedente'},
    {nume: 11, to:'nulidad grave', type:'def' , name: 'Improcedente'},
    {nume: 12, to:'nulidad grave', type:'def' , name: 'Se condena'},
    {nume: 13, to:'nulidad grave', type:'def' , name: 'Se absuelve'},
    {nume: 14, to:'nulidad grave', type:'def' , name: 'Se sanciona'},
 
]

export const TypeVotations = [
    {nume: 1, name: 'Unanimidad'},
    {nume: 2, name: 'Mayoría'},
    {nume: 3, name: 'Returno por no alcanzar la mayoría'},
    {nume: 4, name: 'Retiro para nuevo estudio'}
]

export const TypeTraffic  = [
    {
        'handle' : 'amarillo',
        'title' : 'En tiempo',
        'icon' : 'access_time',
        'class' : 'green-fg'
    },
    {
        'handle' : 'naranja',
        'title' : 'Rezago',
        'icon' : 'access_time',
        'class' : 'orange-fg'
    },
    {
        'handle' : 'rojo',
        'title' : 'Fuera de término',
        'icon' : 'access_time',
        'class' : 'red-fg'
    }
]

//////////////
export const deterNotification = [
    {nume:"acuerdo", name:"Acuerdo"},
    {nume:"sentencia definitiva", name:"Sentencia definitiva"},
    {nume:"sentencia interlocutoria", name:"Sentencia interlocutoria"}
]

//////

export const TypeFiltersAgreementsSecretarioGeneral = [
    {handle: 'pendiente', name:'En revisión', icon: 'history', class:'orange-fg'},
 /* {handle: 'pendiente', name:'En revisión presidencia', icon: 'history', class:'orange-fg'}, */
    {handle: 'firma_pleno', name:'En firma del pleno', icon: 'history', class:'blue-fg'},
    {handle: 'corregir', name:'En corrección', icon: 'history', class:'red-fg'},
    {handle: 'corregir_adjunto', name:'Pendiente de corregir', icon: 'history', class:'red-fg'},
    {handle: 'firmado_magistrado', name:'Pendiente por firmar', icon: 'history', class:'orange-fg'},
    {handle: 'firmado_secretario', name:'Firmado/acordado', icon: 'history', class:'green-fg'},
    {handle: 'atendido_adjunto', name:'Pendiente de revisión', icon: 'history', class:'purple-fg'},

]

export const TypeChildProceduresSentenceSecretarioGeneral = [
    {nume: 3, name: 'Recursos'},
    {nume: 4, name: 'Incidentes'},
]

export const TypeChildResourceSentenceSecretarioGeneral = [
    {nume: 1, name: 'Recurso de queja'},
    {nume: 2, name: 'Recurso de reclamación'},
    {nume: 3, name: 'Recurso de revisión'},
    {nume: 4, name: 'Recurso de apelación'},
    {nume: 5, name: 'Recurso de inconformidad'},

]

export const TypeChildIncidentSentenceSecretarioGeneral = [
    {nume: 5, name: 'Incidente de aclaracion de sentencia'},
    {nume: 1, name: 'Incidente de acumulación de autos'},
    {nume: 7, name: 'Incidente de cumplimiento sustituo'},
    {nume: 8, name: 'Incidente de falsedad de documentos'},
    {nume: 4, name: 'Incidente de incompetencia'},
    {nume: 3, name: 'Incidente de interrupción del procedimiento por muerte o por disolución en el caso de las personas morales'},
    {nume: 6, name: 'Incidente de liquidación'},
    {nume: 2, name: 'Incidente de nulidad de notificaciones'},
    {nume: 9, name: 'Incidente de medidas cautelares'},

]

////
export const UsersNotify = [
    {
        nume:1,
        name:'Particular'
    },
    {
        nume:2,
        name:'Autoridad'
    },
    {
        nume:3,
        name:'Tercero perjudicado'
    },
    {
        nume:4,
        name:'Perito'
    }
]
