import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from './data/app-config.service';
import { TypeFile } from "../main/promotions/demanda-form/options.data.form";
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class DocumentProvider {
    private urlPromotions : string;
    private urlAgreements : string;
    private urlPublicFiles: string;
    private urlAcceptAgreement: string;
    private urlSentences: string;
    private urlAgreementCorrection: string;
    private urlConstanciesPreview: string;
    constructor(private http: HttpClient, private settings: AppConfigService) {
        this.urlPromotions  = this.settings.getUrlApi() + 'promotions';
        this.urlAgreements  = this.settings.getUrlApi() + 'agreements';
        this.urlSentences  = this.settings.getUrlApi() + 'sentences';
        this.urlPublicFiles = this.settings.getUrlApi() + 'files_sign';
        this.urlAcceptAgreement = this.settings.getUrlApi() + 'agreement-accept';
        this.urlAgreementCorrection = this.settings.getUrlApi() + 'agreement-file';
        this.urlConstanciesPreview = this.settings.getUrlApi() + 'constancies';
    }
    
    registers(credentials) {
        const  haciendoalgo = (resolve, reject) => {
            if (credentials.une !== '') {
                setTimeout(() =>{
                    resolve("Stuff worked!");
                    console.log('terminado!!')
                }, 5000);
            }
            else {
                reject(Error("It broke"));
            }
        }
        return new Promise( haciendoalgo );
    }

    registerDocument(dataDocument) {
        console.log('datos del form', dataDocument);
        let optionHeaders   = this.settings.getHeadersMultipart();
        return this.http.post(this.urlPromotions , dataDocument, optionHeaders);
    }
    
    getDocumentsUploaded(promotion_id) {
        const optionHeaders   = this.settings.getHeadersJson();
        const documentsUrl = this.urlPromotions + `/${ promotion_id }/promotion-documents`;

        return this.http.get(documentsUrl , optionHeaders);
    }

    setAgreements(dataPromotion) {
        let optionHeaders   = this.settings.getHeadersMultipart();
        const url = this.settings.getUrlApi() + 'agreements';
        return this.http.post(this.urlAgreements , dataPromotion, optionHeaders);
    }

    getPromotionsByAgreement(agreement_id) {
        const optionHeaders   = this.settings.getHeadersJson();
        const documentsUrl = this.urlAgreements + `/${ agreement_id }/promotions`;

        return this.http.get(documentsUrl , optionHeaders);
    }

    getPromotionsBySentence(sentence_id) {
        const optionHeaders   = this.settings.getHeadersJson();
        const documentsUrl = this.urlSentences + `/${ sentence_id }/promotions`;

        return this.http.get(documentsUrl , optionHeaders);
    }
    
    getTypeDocument(uid_doc: string) {
        const tipo = TypeFile.find(type => type.uid === uid_doc);
        if(tipo !== undefined) {
            return tipo.name;
        } else {
            return uid_doc;
        }
    }

    getDocumentBlob(link): Observable<any> {
        let headers = new HttpHeaders()
        headers = headers.set("Accept", "application/pdf")
                        .set('Authorization', 'Bearer ' + sessionStorage.getItem('tk'))
        return this.http.get(link, { headers: headers, responseType: "blob" })
    }

    getPublicFiles(folder, file){
        let optionHeaders = this.settings.getHeadersBlob();
        return this.http.get(this.urlPublicFiles+'/'+folder+'/'+file, {responseType: 'arraybuffer'});
    }

    acceptAgreement(data){
        let optionHeaders = this.settings.getHeadersMultipart();
        return this.http.post(this.urlAcceptAgreement, data, optionHeaders);
    }

    sendAgreementCorrection(data){
        let optionHeaders = this.settings.getHeadersMultipart();
        return this.http.post(this.urlAgreementCorrection, data, optionHeaders)
    }


    getConstanciesPreviewFiles(type,data){
        let optionHeaders = this.settings.getHeadersMultipart();
       // return this.http.post(this.urlConstanciesPreview,data, optionHeaders)
        let params="";
       data.forEach(element => {
        let param=`&${element.key}=${element.value}`;
        params=params+param;
       });

       return this.http.get<Blob>(`${this.urlConstanciesPreview}?type=${type}${params}`, {headers:optionHeaders.headers, observe: 'response', responseType: 'blob' as 'json' })

    }
}