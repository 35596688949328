export const TypeDoc = [
    { id: 1, handle: 'inicial', registry: 'inicial', sala:'2 3', judgment: 'nulidad lesividad', to_user: 'citizen adviser authority', name: 'DEMANDA INICIAL', title: 'Demanda inicial'},
    { id: 2, handle: 'recurso', registry: 'inicial', sala:'2 3', judgment: 'nulidad lesividad recurso', to_user: 'citizen authority adviser', name: 'RECURSO DE REVISIÓN', title: 'Recurso de revisión'},
    { id: 3, handle: 'apelacion', registry: 'inicial', sala:'2 3', judgment: 'grave recurso', to_user: 'citizen authority adviser', name: 'RECURSO DE APELACIÓN', title: 'Recurso de apelación'},
    { id: 4, handle: 'inconformidad', registry: 'inicial', sala:'2 3', judgment: 'nograbe recurso', to_user: 'citizen authority adviser', name: 'RECURSO DE INCONFORMIDAD', title: 'Recurso de inconformidad'},
    { id: 5, handle: 'queja', registry: 'inicial', sala:'1 2 3', judgment: 'nulidad lesividad grave recurso', to_user: 'citizen adviser authority', name: 'RECURSO DE QUEJA', title: 'Recurso de queja'},
/*     { id: 6, handle: 'revocacion', registry: 'inicial', sala:'1 2 3', judgment: 'nulidad lesividad grave recurso', to_user: 'citizen adviser authority', name: 'RECURSO DE REVOCACIÓN', title: 'Recurso de revocación'},
 */    { id: 57, handle: 'reclamacion', registry: 'inicial', sala:'1 2 3', judgment: 'nulidad lesividad grave recurso', to_user: 'citizen adviser authority', name: 'RECURSO DE RECLAMACIÓN', title: 'Recurso de reclamación'},
    { id: 7, handle: 'responsabilidadpatrimonial', registry: 'inicial', sala:'1 2 3', judgment: 'nulidad lesividad grave recurso', to_user: 'citizen adviser authority', name: 'JUICIO DE RESPONSABILIDAD PATRIMONIAL', title: 'Juicio de responsabilidad patrimonial'},
    { id: 8, handle: 'paraprocesales', registry: 'inicial', sala:'1 2 3', judgment: 'nulidad lesividad grave recurso', to_user: 'citizen adviser authority', name: 'PROCEDIMIENTOS PARAPROCESALES', title: 'Procedimientos paraprocesales'},
    { id: 9, handle: 'acumulacion', registry: 'inicial', sala:'1 2 3', judgment: 'nulidad lesividad grave nograbe incidente', to_user: 'citizen authority adviser', name: 'INCIDENTE DE ACUMULACIÓN DE AUTOS', title: 'Incidente de acumulación de autos'},
    { id: 10, handle: 'falsedaddocumento', registry: 'inicial', sala:'1 2 3', judgment: 'nulidad lesividad grave nograbe incidente', to_user: 'citizen authority adviser', name: 'INCIDENTE DE FALSEDAD DEL DOCUMENTO', title: 'Incidente de falsedad del documento'},
    { id: 11, handle: 'incompetencia', registry: 'inicial', sala:'1 2 3', judgment: 'nulidad lesividad grave nograbe incidente', to_user: 'citizen authority adviser', name: 'INCIDENTE DE INCOMPETENCIA EN RAZÓN DE TERRITORIO', title: 'Incidente de incompetencia en razón de territorio'},
    { id: 11, handle: 'informeresponsabilidad', registry: 'inicial', sala:'2 3', judgment: 'grave', to_user: 'citizen authority adviser', name: 'INFORME DE PRESUNTA RESPONSABILIDAD ADMINISTRATIVA', title: 'Informe de presunta responsabilidad administrativa'},
    { id: 12, handle: 'nulidad', registry: 'inicial', sala:'1 2 3', judgment: 'nulidad lesividad grave nograbe incidente', to_user: 'citizen authority adviser', name: 'INCIDENTE DE NULIDAD DE NOTIFICACIONES', title: 'Incidente de nulidad de notificaciones'},
    { id: 13, handle: 'incidenteimpedimento', registry: 'inicial', sala:'1 2 3', judgment: 'nulidad lesividad grave nograbe incidente', to_user: 'citizen authority adviser', name: 'INCIDENTE DE RECUSACIÓN POR CAUSA DE IMPEDIMENTO', title: 'Incidente de recusación por causa de impedimento'},
    { id: 14, handle: 'incidentesuspension', registry: 'inicial', sala:'1 2 3', judgment: 'nulidad lesividad grave nograbe incidente', to_user: 'citizen authority adviser', name: 'INCIDENTE DE INCUMPLIMIENTO DE SUSPENSIÓN', title: 'Incidente de incumplimiento de suspensión'},
    { id: 15, handle: 'incidenteinnominado', registry: 'inicial', sala:'1 2 3', judgment: 'nulidad lesividad grave nograbe incidente', to_user: 'citizen authority adviser', name: 'INCIDENTE INNOMINADO', title: 'Incidente innominado'},
    { id: 15, handle: 'incidentecautelares', registry: 'inicial', sala:'1 2 3', judgment: 'nulidad lesividad grave nograbe incidente', to_user: 'citizen authority adviser', name: 'INCIDENTE DE MEDIDAS CAUTELARES', title: 'Incidente de medidas cautelares'},
    { id: 16, handle: 'excusas', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen authority adviser', name: 'EXCUSA', title: 'Excusa', color : '#692414', customStyle: ''},
    { id: 17, handle: 'alegato', registry: 'promocion', sala:'2 3', judgment: 'nulidad lesividad grave', to_user: 'citizen authority adviser', name: 'ALEGATOS', title: 'Alegatos',},
    { id: 18, handle: 'ampliacion', registry: 'promocion', sala:'2 3', judgment: 'nulidad lesividad', to_user: 'citizen adviser authority', name: 'AMPLIACIÓN DE DEMANDA', title: 'Ampliación de demanda',},
    { id: 19, handle: 'apersonamiento', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad', to_user: 'authority', name: 'APERSONAMIENTO', title: 'apersonamiento',},
    { id: 20, handle: 'comparecencia', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad grave', to_user: 'citizen authority adviser', name: 'COMPARECENCIA', title: 'Comparecencia',},
    { id: 21, handle: 'agravios', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad grave nograbe',  to_user: 'citizen authority adviser', name: 'CONTESTACIÓN DE AGRAVIOS', title: 'Contestación de agravios',},
    { id: 22, handle: 'contestampliacion', registry: 'promocion', sala:'2 3', judgment: 'nulidad lesividad', to_user: 'authority', name: 'CONTESTACIÓN DE AMPLIACIÓN DE DEMANDA', title: 'contestación de ampliación de demanda',},
    { id: 23, handle: 'contestampliacionlesividad', registry: 'promocion', sala:'2 3', judgment: 'nulidad lesividad', to_user: 'authority', name: 'CONTESTACIÓN A LA AMPLIACIÓN DE DEMANDA (LESIVIDAD) ', title: 'Contestación a la ampliación de demanda (lesividad)',},
    { id: 24, handle: 'contestacion', registry: 'promocion', sala:'2 3', judgment: 'nulidad lesividad', to_user: 'authority', name: 'CONTESTACIÓN DE LA DEMANDA', title: 'contestación de la demanda',},
    { id: 25, handle: 'cumplimiento', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad', to_user: 'citizen authority adviser', name: 'CONVENIO DE CUMPLIMIENTO DE SENTENCIA', title: 'Convenio de cumplimiento de sentencia',},
    { id: 26, handle: 'sustituto', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad', to_user: 'citizen authority adviser', name: 'CONVENIO DE CUMPLIMIENTO SUSTITUTO DE SENTENCIA', title: 'Convenio de cumplimiento sustituto de sentencia',},
    { id: 27, handle: 'desahogo', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen authority adviser', name: 'DESAHOGO DE VISTA', title: 'desahogo de vista',},
    { id: 28, handle: 'nuevodomicilio', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen authority adviser', name: 'DESIGNACIÓN DE NUEVO DOMICILIO ELECTRÓNICO', title: 'Designación de nuevo domicilio electrónico',},
    { id: 29, handle: 'representante', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad', to_user: 'citizen authority adviser', name: 'DESIGNACIÓN DE REPRESENTANTE COMÚN', title: 'Designación de representante común',},
    { id: 30, handle: 'desistimiento', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen authority adviser', name: 'DESISTIMIENTO', title: 'Desistimiento',},
    { id: 31, handle: 'cumplimientoejecucion', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad', to_user: 'citizen authority adviser', name: 'EJECUCIÓN DE CUMPLIMIENTO DE SENTENCIA', title: 'Ejecución de cumplimiento de sentencia',},
    { id: 32, handle: 'excitativa', registry: 'promocion', sala:'1', judgment: 'nulidad lesividad grave', to_user: 'citizen authority adviser', name: 'EXCITATIVA DE JUSTICIA', title: 'Excitativa de justicia',},
    { id: 33, handle: 'inactividadprocesal', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen authority adviser', name: 'INACTIVIDAD PROCESAL', title: 'Inactividad procesal',},
    { id: 34, handle: 'informacumplimiento', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad', to_user: 'authority', name: 'INFORMA CUMPLIMIENTO', title: 'informa cumplimiento',},
    { id: 35, handle: 'ocular', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad grave', to_user: 'citizen adviser', name: 'INSPECCIÓN OCULAR', title: 'inspección ocular',},
    { id: 36, handle: 'perito', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad grave', to_user: 'citizen authority adviser', name: 'NOMBRAMIENTO DE PERITO', title: 'nombramiento de perito',},
    { id: 37, handle: 'objecion', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad grave', to_user: 'citizen authority adviser', name: 'OBJECIÓN DE DOCUMENTOS', title: 'objeción de documentos',},
    { id: 38, handle: 'prueba', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad grave', to_user: 'citizen authority adviser', name: 'OFRECIMIENTO DE PRUEBA', title: 'ofrecimiento de prueba',},
    { id: 39, handle: 'directo', registry: 'promocion', sala:'1', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen authority adviser', name: 'PRESENTACIÓN DE DEMANDA DE AMPARO DIRECTO', title: 'presentación de demanda de amparo directo',},
    { id: 40, handle: 'promocion', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen authority adviser', name: 'PROMOCIÓN GENÉRICA', title: 'Promoción genérica',},
    { id: 41, handle: 'suspensionpromocion', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad', to_user: 'citizen authority adviser', name: 'PROMOCIÓN RELACIONADA CON SUSPENSIÓN', title: 'Promoción relacionada con suspensión',},
    { id: 42, handle: 'superveniente', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad grave', to_user: 'citizen authority adviser', name: 'PRUEBA SUPERVENIENTE', title: 'prueba superveniente',},
    { id: 43, handle: 'autorizados', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen authority adviser', name: 'REVOCACIÓN DE AUTORIZADOS Y DESIGNACIÓN DE NUEVOS AUTORIZADOS', title: 'Revocación de autorizados y designación de nuevos autorizados',},
    { id: 44, handle: 'informe', registry: 'promocion',  sala:'1 2 3', judgment: 'nulidad lesividad', to_user: 'authority', name: 'RINDE INFORME', title: 'rinde informe',},
    { id: 45, handle: 'mediacion', registry: 'promocion',  sala:'1 2 3', judgment: 'nulidad lesividad', to_user: 'citizen authority adviser', name: 'SOLICITUD DE APLICACIÓN DE MEDIOS ALTERNOS DE SOLUCIÓN DE CONTROVERSIAS', title: 'solicitud de aplicación de medios alternos de solución de controversias',},
    { id: 46, handle: 'archivo', registry: 'promocion',  sala:'1 2 3', judgment: 'nulidad lesividad grave',to_user: 'citizen authority adviser', name: 'SOLICITUD DE ARCHIVO', title: 'solicitud de archivo',},
    { id: 47, handle: 'copias', registry: 'promocion',  sala:'1 2 3', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen authority adviser', name: 'SOLICITUD DE COPIAS', title: 'Solicitud de copias',},
    { id: 48, handle: 'devolucion', registry: 'promocion',  sala:'1 2 3', judgment: 'nulidad lesividad grave', to_user: 'citizen authority adviser', name: 'SOLICITUD DE DEVOLUCIÓN DE FIANZAS', title: 'Solicitud de devolución de fianzas',},
    { id: 49, handle: 'ejecutoria', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen authority adviser', name: 'SOLICITUD DE EJECUTORIA', title: 'solicitud de ejecutoria',},
    { id: 50, handle: 'prorroga', registry: 'promocion', sala:'1 2 3', judgment: '', to_user: 'citizen authority adviser', name: 'SOLICITUD DE PRÓRROGA', title: 'solicitud de prórroga',},
    { id: 51, handle: 'regularizacion', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad grave', to_user: 'citizen authority adviser', name: 'SOLICITUD DE REGULARIZACIÓN DE PROCEDIMIENTO', title: 'solicitud de regularización de procedimiento',},
    { id: 52, handle: 'solicitarequerimiento', registry: 'promocion', sala:'1 2 3', judgment: 'nulidad lesividad', to_user: 'citizen authority adviser', name: 'SOLICITUD DE REQUERIMIENTO', title: 'solicitud de requerimiento',},
    { id: 53, handle: 'solicitasuspension', registry: 'promocion', sala:'2 3', judgment: 'nulidad lesividad', to_user: 'citizen adviser', name: 'SOLICITUD DE SUSPENSIÓN', title: 'solicitud de suspensión',},
    { id: 54, handle: 'testigos', registry: 'promocion', sala:'2 3', judgment: 'nulidad lesividad grave', to_user: 'citizen authority adviser', name: 'SUSTITUCIÓN DE TESTIGOS ', title: 'Sustitución de testigos ',},
    { id: 55, handle: 'otro', registry: 'inicial promocion', sala:'1 2 3 4 5 6 7 8 9', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen authority perito adviser', name: 'OTRO', title: 'otro',},
    //DATOS ESPECIFICOS
    {id: 56, handle: 'suspensioninicial', registry: '', sala:'', judgment: '', to_user: '', title: 'suspención inicial' ,name: 'SUSPENCIÓN INICIAL'},

]

export const TypeFile = [
    { name: 'copia simple', uid:'simple'},
    { name: 'copia certificada', uid:'certificada'},
    { name: 'original con firma', uid:'firmada'},
    { name: 'original sin firma', uid:'original'},
]

export const TipoPromovente = [
    {  nume:'1', type:'promocion revision', name:'Actor'},
    {  nume:'2', type:'promocion revision', name:'Autoridad Demandada'},
    {  nume:'3', type:'promocion revision', name:'Tercero Perjudicado'},
    {  nume:'4', type:'promocion', name:'Recurrente'},
    {  nume:'5', type:'promocion revision', name:'Otro'},
    {  nume:'6', type:'especializada', name:'Presunto responsable'},
    {  nume:'7', type:'especializada', name:'Investigadora'},
    {  nume:'8', type:'especializada', name:'Substanciadora'},
    {  nume:'9', type:'especializada', name:'Resolutora'},
    {  nume:'10', type:'especializada', name:'Terceros'},
    {  nume:'11', type:'especializada', name:'Otra'},
]

export const TipoProcedimiento = [
    { nume: 1, name:'Juicio ordinario'},
    { nume: 2, name:'Juicio sumario'},
    { nume: 8, name:'Procedimiento de responsabilidades por falta administrativa grave'},
    { nume: 9, name:'Procedimiento de cumplimiento de sentencia'},
    { nume: 10, name:'Conflicto de competencia'},
    { nume: 11, name:'Excitativa de justicia'},
    { nume: 12, name:'Excusa'},
];

export const Responsabilidad = [
    {  nume:'0', name:'Responsabilidad Administrativa'},
    {  nume:'0', name:'Recurso de Inconformidad'},
    {  nume:'0', name:'Recurso de Reclamación'},
    {  nume:'0', name:'Recurso de Apelación'},
]

export const OrganoJurisdiccional = [
    //A: 'acumulacion agravios apersonamiento archivo autorizados comparecencia copias cumplimiento cumplimientoejecucion desahogo desistimiento devolucion disolucion ejecutoria excusas falsedaddocumento inactividadprocesal incidentesustituto incidenteaclaracion incompetencia informacumplimiento informe liquidacion mediacion nuevodomicilio nulidad objecion ocular perito promocion prorroga prueba queja reclamacion regularizacion representante solicitarequerimiento superveniente suspencionpromocion sustituto',
    //SS: 'directo excitativa incidentecautelares cautelares inconformidad',
    //SR: 'alegato ampliacion ampliacionlesividad apelacion contestacion contestacionlesividad contestampliacion informeresponsabiliadad inicial recurso solicitasuspension testigos',
    { 
        nume:1, 
        depa:'Sala Superior',
        conf:'copias autorizados nuevodomicilio comparecencia cumplimiento sutituto directo indirecto reclamacion aclaracion', 
    },
    {   nume:2, 
        depa:'Primera Sala',
        conf:'inicial inicialsinsuspension suspensioninicial responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo indirecto aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:3, 
        depa:'Segunda Sala',
        conf:'inicial inicialsinsuspension suspensioninicial responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo indirecto aclaracion queja reclamacion acumulacion disolucion incompetencia',
    }
]

export const OrganoJurisdiccionalCorrespondencia = [
    { 
        nume:1, 
        depa:'Sala Superior',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento incidentesustituto nuevodomicilio representante desistimiento desistimientorecurso cumplimientoejecucion excitativa excusas impedimentos inactividad incidenteaclaracion acumulacion sustituto incompetencia disolucion directo responsabilidad promocion suspensionpromocion apelacion inconformidad recurso autorizados copias ejecucionsanciones devolucion cautelares testigos', 
    },
    {   nume:2, 
        depa:'Sala Regional Acapulco I',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento incidentesustituto inicialsinsuspension nuevodomicilio representante desistimiento desistimientorecurso cumplimientoejecucion excitativa inactividad incidenteaclaracion acumulacion sustituto disolucion nulidad directo juicionulidad responsabilidad promocion suspensionpromocion queja reclamacion autorizados copias ejecucionsanciones devolucion cautelares testigos',
    },
    {   nume:3, 
        depa:'Sala Regional Acapulco II',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento incidentesustituto inicialsinsuspension nuevodomicilio representante desistimiento desistimientorecurso cumplimientoejecucion excitativa inactividad incidenteaclaracion acumulacion sustituto disolucion nulidad directo juicionulidad responsabilidad promocion suspensionpromocion queja reclamacion autorizados copias ejecucionsanciones devolucion cautelares testigos',
    },
    {   nume:4, 
        depa:'Sala Regional Altamirano',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento incidentesustituto inicialsinsuspension nuevodomicilio representante desistimiento desistimientorecurso cumplimientoejecucion excitativa inactividad incidenteaclaracion acumulacion sustituto disolucion nulidad directo juicionulidad responsabilidad promocion suspensionpromocion queja reclamacion autorizados copias ejecucionsanciones devolucion cautelares testigos',
    },
    {   nume:5, 
        depa:'Sala Regional Chilpancingo',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento incidentesustituto inicialsinsuspension nuevodomicilio representante desistimiento desistimientorecurso cumplimientoejecucion excitativa inactividad incidenteaclaracion acumulacion sustituto disolucion nulidad directo juicionulidad responsabilidad promocion suspensionpromocion queja reclamacion autorizados copias ejecucionsanciones devolucion cautelares testigos',
    },
    {   nume:6, 
        depa:'Sala Regional Iguala',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento incidentesustituto inicialsinsuspension nuevodomicilio representante desistimiento desistimientorecurso cumplimientoejecucion excitativa inactividad incidenteaclaracion acumulacion sustituto disolucion nulidad directo juicionulidad responsabilidad promocion suspensionpromocion queja reclamacion autorizados copias ejecucionsanciones devolucion cautelares testigos',
    },
    {   nume:7, 
        depa:'Sala Regional Ometepec',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento incidentesustituto inicialsinsuspension nuevodomicilio representante desistimiento desistimientorecurso cumplimientoejecucion excitativa inactividad incidenteaclaracion acumulacion sustituto disolucion nulidad directo juicionulidad responsabilidad promocion suspensionpromocion queja reclamacion autorizados copias ejecucionsanciones devolucion cautelares testigos',
    },
    {   nume:8, 
        depa:'Sala Regional Tlapa',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento incidentesustituto inicialsinsuspension nuevodomicilio representante desistimiento desistimientorecurso cumplimientoejecucion excitativa inactividad incidenteaclaracion acumulacion sustituto disolucion nulidad directo juicionulidad responsabilidad promocion suspensionpromocion queja reclamacion autorizados copias ejecucionsanciones devolucion cautelares testigos',
    },
    {   nume:9, 
        depa:'Sala Regional Zihuatanejo',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento incidentesustituto inicialsinsuspension nuevodomicilio representante desistimiento desistimientorecurso cumplimientoejecucion excitativa inactividad incidenteaclaracion acumulacion sustituto disolucion nulidad directo juicionulidad responsabilidad promocion suspensionpromocion queja reclamacion autorizados copias ejecucionsanciones devolucion cautelares testigos',
    }
]


export const TipoRecurso = [
    { nume: 1, name:'Juicio de nulidad'},
    /*{ nume: 2, name:'Juicio sumario'},*/
    { nume: 3, name:'Recurso de revisión'},
    { nume: 4, name:'Recurso de revocación'},
    { nume: 5, name:'Recurso de reclamación'},
    { nume: 6, name:'Recurso de inconformidad'},
    { nume: 7, name:'Recurso de apelación'},
    // { nume: 8, name:'Procedimiento de responsabilidades administrativas'},
    { nume: 8, name:'Juicio por responsabilidad administrativa grave'},
    { nume: 9, name:'Procedimiento de cumplimiento de sentencia'},
    { nume: 10, name:'Conflicto de competencia'},
    { nume: 11, name:'Excitativa de justicia'},
   // { nume: 12, name:'Excusa'},
];

export class FileAnexo {
    name:string;
    file: any;
    error: boolean;
    upload: boolean;
    size: string;
    type: string;
    desc: string;
    txtBtnUp: string;
    numPages: string;
    txtError: string;
    constructor(name: string){
        { 
            this.name = name; 
            this.file = undefined;
            this.error = false; 
            this.txtError = '';
            this.upload = false;
            this.size = '';
            this.type = '';
            this.desc = ''; 
            this.txtBtnUp = 'Agregar archivo';
            this.numPages = '0';
        }
    }
} 

export const TipoJuicios = [
    {nume:1, type: "fiscal" , name:"Fiscal"},
    {nume:2, type: "administrativo" , name:"Administrativo"},
    {nume:3, type: "grave" , name:"Responsabilidad administrativa grave"},
    {nume:4, type: "responsabilidadpatrimonial" , name:"Juicio de responsabilidad patrimonial"},
    {nume:5, type: "procedimientosparaprocesales" , name:"Procedimientos paraprocesales"},
    {nume:6, type: "lesividad" , name:"Juicio de lesividad" },
    /*{ nume: 1,  name: 'Juicio Administrativo'},
    FISCAL 
ADMINISTRATIVO
RESPONSABILIDAD ADMINISTRATIVA GRAVE
JUICIO DE RESPONSABILIDAD PATRIMONIAL
PROCEDIMIENTOS PARAPROCESALES 
JUICIO DE LESIVIDAD
    { nume: 2,  name: 'Juicio Fiscal'}, 
    { nume: 15, name:  'Juicio de Lesividad'},
    { nume: 3,  name: 'Recurso de Queja'},
    { nume: 4,  name: 'Recurso de Revisión'},
    { nume: 5,  name: 'Cumplimiento de Sentencia en Sala Superior'},
    { nume: 6,  name: 'Cuadernillo de Amparo en Sala Superior'},
    { nume: 7,  name: 'Excitativas de Justicia'},
    { nume: 8,  name: 'Conflicto de Competencia entre Salas Regionales'},
    { nume: 9,  name: 'Procedimiento de Responsabilidad Administrativa'},
    { nume: 10, name:  'Recurso de Reclamación'},
    { nume: 11, name:  'Recurso de Apelación'},
    { nume: 12, name:  'Procedimiento de Responsabilidades Administrativas'},
    { nume: 16, name:  'Procedimiento de Responsabilidades Administrativas por Faltas Graves'},
    { nume: 13, name:  'Recurso de Inconformidad'},
    { nume: 14, name:  'Incidentes'},*/

   /* { nume: 15, name:  'Investigación'},*/
];

export const ListadoAcuerdos = [
    {nume: 0,  name:'Otro'},
    {nume: 1,  name:'Admisión'},
    {nume: 2,  name:'Desechamiento'},
    {nume: 3,  name:'Ampliación'},
    {nume: 4,  name:'Sentencia'},
    {nume: 5,  name:'Contestación de Demanda'},
    {nume: 6,  name:'Audiencia'},
    {nume: 7,  name:'Nulidad de Notificación'},
    {nume: 8,  name:'Acuerdo de recepción de expediente'},
    {nume: 9,  name:'Acuerdo de reclasificación'},
    {nume: 10, name: 'Acuerdo de devolución'},
    {nume: 11, name: 'Acuerdo de envío'},
    {nume: 12, name: 'Cierre de instrucción'},
    {nume: 13, name: 'Audiencia diferida'},
    {nume: 14, name: 'Contestación a la ampliación'},
    {nume: 15, name: 'Ampliación de demanda'},
    {nume: 16, name: 'Manifestaciones'},
    {nume: 17, name: 'Exhibe expediente'},
    {nume: 18, name: 'Ofrecimiento de pruebas'},
    {nume: 19, name: 'Aclaración sentencia'},
    {nume: 20, name: 'Desahogo'},
    {nume: 21, name: 'Reposición '},
    {nume: 22, name: 'Copias'},
    {nume: 23, name: 'Alegatos'},
    {nume: 24, name: 'Cumplimiento'},
    {nume: 25, name: 'Conciliación'},
    {nume: 26, name: 'Ejecutoria'},
    {nume: 27, name: 'Archivo'},
    {nume: 28, name: 'Incompetencia'},
    /*{nume: 29, name: 'Túrnese a proyectos'},*/
    {nume: 30, name: 'Acuse de recibo'},
    {nume: 31, name: 'Admisión de pruebas'},
    {nume: 32, name: 'Requerimiento'},
    {nume: 33, name: 'Desahogo de requerimiento'},
    {nume: 34, name: 'Presentación de alegatos'},
    {nume: 35, name: 'Remisión a sala superior'},
    {nume: 36, name: 'Acuerdo donde señala domicilio el presunto, la investigadora o substanciadora'},
    {nume: 37, name: 'Regularización'},
    {nume: 38, name: 'Reserva'},
    {nume: 39, name: 'Admisión de queja'},
    {nume: 40, name: 'Recepción de documentos'},
    {nume: 41, name: 'Acumulación'},
    {nume: 42, name: 'Conclusión'},
    {nume: 43, name: 'Remisión'},
    {nume: 44, name: 'Sala a la que se remite'},
];

export const TipoParte = [
    { nume: 1, name:'Parte actora'},
    { nume: 2, name:'Defensor de oficio'},
    { nume: 3, name:'Autoridad'},
    { nume: 4, name:'Todas las partes'},
    { nume: 5, name:'Otro'},
   /* { nume: 4, name: 'Recurrente'},
    { nume: 5, name: 'Autoridad vinculada'},
    /*{ nume: 6, name: 'Delegado fiscal'},
    { nume: 7, name: 'Juzgado de distrito'},
    { nume: 8, name: 'Órgano jurisdiccional exhortante'},
    { nume: 45, name: 'Autoridad acusadora'},
    { nume: 9, name: 'Autoridad investigadora'},
    { nume: 10, name: 'Autoridad substanciadora'},
    { nume: 11, name: 'Presunto responsable'},
    { nume: 47, name: 'Presunto infractor'},
    { nume: 12, name: 'Denunciante'},
    { nume: 13, name: 'Notifíquese'},*/
    /*
    Recurso de apelación
    Recurso de inconformidad

    Alegatos
    Ampliacion
    Correspondencia
    Excitativa de justicia
    Incidente de nulidad de notificaciones
    Juicio de amparo (solicita informes previos)
    Promocion relacionada con suspension
    Promocion
    

    { nume: 14, name: 'Actor - Recurrente'},
    { nume: 15, name: 'Actor - Tercer perjudicado'},
    { nume: 16, name: 'Actor - Autoridad vinculada'},
    { nume: 17, name: 'Actor - Delegado fiscal'},
    { nume: 18, name: 'Actor - Juzgado de distrito'},
    { nume: 19, name: 'Actor - Órgano jurisdiccional exhortante'},
    { nume: 20, name: 'Actor - Autoridad investigadora'},
    { nume: 21, name: 'Actor - Autoridad substanciadora'},
    { nume: 22, name: 'Actor - Presunto responsable'},
    { nume: 23, name: 'Actor - Denunciante'},
    { nume: 24, name: 'Actor - Notifíquese'},
    { nume: 25, name: 'Actor - Autoridad Demandada'},
    { nume: 26, name: 'Actor - Autoridad Demandada - Recurrente'},
    { nume: 27, name: 'Actor - Autoridad Demandada - Tercer perjudicado'},
    { nume: 28, name: 'Actor - Autoridad Demandada - Autoridad vinculada'},
    { nume: 29, name: 'Actor - Autoridad Demandada - Delegado fiscal'},
    { nume: 30, name: 'Actor - Autoridad Demandada - Juzgado de distrito'},
    { nume: 31, name: 'Actor - Autoridad Demandada - Entidad exhortante'},
    { nume: 32, name: 'Actor - Autoridad Demandada - Autoridad investigadora'},
    { nume: 33, name: 'Actor - Autoridad Demandada - Autoridad substanciadora'},
    { nume: 34, name: 'Actor - Autoridad Demandada - Presunto responsable'},
    { nume: 35, name: 'Actor - Autoridad Demandada - Denunciante'},
    { nume: 36, name: 'Actor - Autoridad Demandada - Notifíquese'},
    { nume: 37, name: 'Autoridad investigadora - Autoridad substanciadora - Presunto responsable - Denunciante'},
    { nume: 38, name: 'Autoridad investigadora - Autoridad substanciadora - Presunto responsable'},
    { nume: 39, name: 'Autoridad investigadora - Autoridad substanciadora'},
    /*{ nume: 40, name: 'Cúmplase'},*/
    /*{ nume: 41, name: 'Tribunal Colegiado de Circuito'},
    { nume: 42, name: 'Auditoria Superior del Estado de Guerrero'},
    /*{ nume: 43, name: 'Interesados'},
    { nume: 44, name: 'Tribunal de Conciliación y Arbitraje del Estado de Guerrero'},
    { nume: 46, name: 'Tribunal Electoral del Estado de Guerrero'},*/
];

export const ListadoAcuerdosSalas = [
    { 
        nume:1, 
        name:'Sala Superior',
        conf:'copias autorizados nuevodomicilio comparecencia cumplimiento sutituto directo reclamacion aclaracion', 
    },
    {   nume:2, 
        name:'Sala Regional Acapulco I',
        conf:'inicial inicialsinsuspension  responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:3, 
        name:'Sala Regional Acapulco II',
        conf:'inicial inicialsinsuspension  responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:4, 
        name:'Sala Regional Altamirano',
        conf:'inicial inicialsinsuspension  responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:5, 
        name:'Sala Regional Chilpancingo',
        conf:'inicial inicialsinsuspension  responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:6, 
        name:'Sala Regional Iguala',
        conf:'inicial inicialsinsuspension  responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:7, 
        name:'Sala Regional Ometepec',
        conf:'inicial inicialsinsuspension  responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:8, 
        name:'Sala Regional Tlapa',
        conf:'inicial inicialsinsuspension  responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:9, 
        name:'Sala Regional Zihuatanejo',
        conf:'inicial inicialsinsuspension  responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo aclaracion queja reclamacion acumulacion disolucion incompetencia',
    }
];

export const TipoSentido = [

    {nume: 1,  sala:'o', type: 'nulidad', name:'Acuerdo recaído a demanda'},
    {nume: 29, sala:'o s', type: 'nulidad', name:'Acuerdo recaído a recurso'},
    {nume: 2,  sala:'o', type: 'nulidad', name:'Acuerdo recaído a contestación'},
    {nume: 3,  sala:'o', type: 'nulidad', name:'Acuerdo recaído a ampliación demanda'},
    {nume: 4,  sala:'o', type: 'nulidad', name:'Acuerdo recaído a incompetencias remitidas por otros organos jurisdiccionales'},
    {nume: 5,  sala:'o', type: 'nulidad', name:'Revocación de la suspensión'},
    {nume: 6,  sala:'o', type: 'nulidad', name:'Modificacion de la suspensión'},
    {nume: 7,  sala:'o', type: 'nulidad', name:'Solicitud de suspensión despues de admitida la demanda'},
    {nume: 8,  sala:'o', type: 'nulidad', name:'Diferir audiencia'},
    {nume: 9,  sala:'o', type: 'nulidad', name:'Regularizar procedimiento'},
    {nume: 10, sala:'o', type: 'nulidad', name:'Pruebas supervenientes'},
    {nume: 11, sala:'o', type: 'nulidad', name:'Designación peritos'},
    {nume: 12, sala:'o', type: 'nulidad', name:'Testigos'},
    {nume: 13, sala:'o', type: 'nulidad', name:'Notificación de manera distinta al boletín jurisdiccional'},
    {nume: 14, sala:'o', type: 'nulidad', name:'Reposición de autos'},
    {nume: 15, sala:'o', type: 'nulidad', name:'Exhorto'},
    {nume: 16, sala:'o', type: 'nulidad', name:'Requisitoria'},
    {nume: 17, sala:'o', type: 'nulidad', name:'Medios de apremio'},
    {nume: 18, sala:'o', type: 'nulidad', name:'Medidas disciplinarias'},
    {nume: 19, sala:'o', type: 'nulidad', name:'Sobreseimiento fuera de audiencia'},
    {nume: 20, sala:'o', type: 'nulidad', name:'Suspension del procedimiento'},
    {nume: 21, sala:'o', type: 'nulidad', name:'Incidentes'},
    {nume: 22, sala:'o', type: 'nulidad', name:'Convenios'},
    {nume: 23, sala:'o', type: 'nulidad', name:'Audiencia de juicio'},
    {nume: 24, sala:'o', type: 'nulidad', name:'Ejecución de sentencia'},
    {nume: 25, sala:'o', type: 'nulidad', name:'Recursos'},
    {nume: 26, sala:'o s', type: 'nulidad', name:'Amparo'},
    {nume: 27, sala:'o', type: 'nulidad', name:'Archivo de expediente totalmente concluido'},
    {nume: 28, sala:'o s', type: 'nulidad', name:'Otro'},

];


export const AditionalOptions=[
    
/* 
    //Recurso Options
    { nume: 1,  acuerdo_id: 25, type:"nulidad", name: "Revisión" },
    { nume: 4,  acuerdo_id: 25, type:"grave", name: "Apelación" },
    { nume: 2,  acuerdo_id: 25, type:"nulidad grave", name: "Reclamación" },
    { nume: 5,  acuerdo_id: 25, type:"grave", name: "Reclamación (R.Grave)" },
    { nume: 3,  acuerdo_id: 25, type:"nulidad grave", name: "Queja" },
    
      //Incidente Options
    { nume: 6,  acuerdo_id: 11,type:"nulidad grave",  name:"Acumulación de autos"},
    { nume: 7,  acuerdo_id: 11,type:"nulidad grave",  name:"Nulidad de notificaciones"},
    { nume: 8, acuerdo_id: 11,type:"nulidad grave",  name:"Interrupción del procedimiento por muerte o por disolución en el caso de las personas morales"},
    { nume: 9, acuerdo_id: 11,type:"nulidad grave",  name:"Incompetencia por razón de territorio"},
    { nume: 10, acuerdo_id: 11,type:"nulidad grave",  name:"Aclaración de sentencia"},
    { nume: 11, acuerdo_id: 11,type:"nulidad grave",  name:"Liquidación"},
    { nume: 12, acuerdo_id: 11,type:"nulidad grave",  name:"Cumplimiento sustituto"},
    { nume: 13, acuerdo_id: 11,type:"nulidad grave",  name:"Falsedad de documento"},
    
    //AMPARO INDIRECTO
    {nume: 14, acuerdo_id: 17,type:"nulidad grave", name:"Se rinde informe"}, 
    {nume: 15, acuerdo_id: 17,type:"nulidad grave", name:"Trámite de amparo"},
    {nume: 16, acuerdo_id: 17,type:"nulidad grave", name:"Sentido de ejecutoria"},
    {nume: 17, acuerdo_id: 17,type:"nulidad grave", name:"Cumplimiento de ejecutoria"},
    
    //Acuerdos frecuentes 
    
    {nume: 18, acuerdo_id: 18,type:"nulidad grave", name:"Domicilio procesal y autorizados"}, 
    {nume: 19, acuerdo_id: 18,type:"nulidad grave", name:"Representante común"},
    {nume: 20, acuerdo_id: 18,type:"nulidad grave", name:"Otros"}, */
  
  
  ];
  
export const AcuerdoSentidoOptions=[
    //Acuerdo recaído a demanda
    {nume: 1, option_id:"",acuerdo_id: 1,type:"", name:"Impedimento para conocer demanda"},
    {nume: 2, acuerdo_id: 1,type:"", name:"Prevencion previo admitir demanda"},
    {nume: 3, acuerdo_id: 1,type:"", name:"Desechamiento de plano de demanda"},
    {nume: 4, acuerdo_id: 1,type:"", name:"Admisión demanda"},
    //Acuerdo recaído a recurso
    {nume: 83, acuerdo_id: 29,type:"", name:"Admite recurso"},
    {nume: 84, acuerdo_id: 29,type:"", name:"Desecha recurso"},
    {nume: 85, acuerdo_id: 29,type:"", name:"Prevención"},
    //ACUERDO RECAÍDO A CONTESTACIÓN
    {nume: 5, acuerdo_id: 2,type:"", name:"Prevención previo admitir contestacion"},
    {nume: 6, acuerdo_id: 2,type:"", name:"Admisión contestación"},
    {nume: 7, acuerdo_id: 2,type:"", name:"Admisión contestación con término para ampliar"},
//ACUERDO RECAÍDO A AMPLICIÓN DEMANDA
	 {nume: 8, acuerdo_id: 3,type:"", name:"Admisión ampliación"},
	 {nume: 9, acuerdo_id: 3,type:"", name:"Desechamiento ampliación"},
//ACUERDO RECAÍDO A INCOMPETENCIAS REMITIDAS POR OTROS ORGANOS JURISDICCIONALES 
	 {nume: 10, acuerdo_id: 4,type:"", name:"Aceptadas"},
	 {nume: 11, acuerdo_id: 4,type:"", name:"Denuncia conflicto competencial"},
//REVOCACIÓN DE LA SUSPENSIÓN
	 {nume: 12, acuerdo_id: 5,type:"", name:"Concedida"},
	 {nume: 13, acuerdo_id: 5,type:"", name:"Negada"},
//MODIFICACION DE LA SUSPENSIÓN
     {nume: 14, acuerdo_id: 6,type:"", name:"Concedida"},
	 {nume: 15, acuerdo_id: 6,type:"", name:"Negada"},
 //SOLICITUD DE SUSPENSIÓN DESPUES DE ADMITIDA LA DEMANDA 
	 {nume: 16, acuerdo_id: 7,type:"", name:"Concedida"},
	 {nume: 17, acuerdo_id: 7,type:"", name:"Negada"},
//DIFERIR AUDIENCIA
//REGULARIZAR PROCEDIMIENTO 
//PRUEBAS SUPERVENIENTES
    {nume: 18, acuerdo_id: 10,type:"", name:"Admisión"},
    {nume: 19, acuerdo_id: 10,type:"", name:"Desechamiento"},
    {nume: 20, acuerdo_id: 10,type:"", name:"Prevención"},
//DESIGNACIÓN PERITOS 
    {nume: 21, acuerdo_id: 11,type:"", name:"Requerimiento aceptación cargo de perito"},//DESIGNACIÓN PERITOS
    {nume: 22, acuerdo_id: 11,type:"", name:"Aceptación y discernimiento cargo peritos"},
    {nume: 23, acuerdo_id: 11,type:"", name:"Plazo para emitir dictamen"},
    {nume: 24, acuerdo_id: 11,type:"", name:"Prórroga plazo para emitir dictamen"},
    {nume: 25, acuerdo_id: 11,type:"", name:"Designación perito tercero en discordia"},
    {nume: 26, acuerdo_id: 11,type:"", name:"Vista a las partes con dictamen"},
    {nume: 27, acuerdo_id: 11,type:"", name:"Objeción dictamen"},
//TESTIGOS  
    {nume: 28, acuerdo_id: 12,type:"", name:"Notificar testigos con apercibimiento"},//TESTIGOS 
    {nume: 29, acuerdo_id: 12,type:"", name:"Requerir a la parte presentar testigos"},
    {nume: 30, acuerdo_id: 12,type:"", name:"Sustitución testigos"},
    {nume: 31, acuerdo_id: 12,type:"", name:"Exhorto desahogo prueba testimonial"},

//NOTIFICACIÓN DE MANERA DISTINTA AL BOLETÍN JURISDICCIONAL

//RESPOSICIÓN DE AUTOS

//EXHORTO
 {nume: 32, acuerdo_id: 15,type:"", name:"Girar exhorto"},
 {nume: 33, acuerdo_id: 15,type:"", name:"Diligenciar exhorto"},
 {nume: 34, acuerdo_id: 15,type:"", name:"Devolver exhorto"},

//REQUISITORIA
 {nume: 35, acuerdo_id: 16,type:"", name:"Girar requisitoria"},
 {nume: 36, acuerdo_id: 16,type:"", name:"Diligenciar requisitoria"},
 {nume: 37, acuerdo_id: 16,type:"", name:"Devolver requisitoria"},

//MEDIOS DE APREMIO
 {nume: 38, acuerdo_id: 17,type:"", name:"Apercibimiento"},
 {nume: 39, acuerdo_id: 17,type:"", name:"Multa de cinco a cien umas"},
 {nume: 40, acuerdo_id: 17,type:"", name:"Presentación de persona con auxilio de fuerza pública"},

//MEDIDAS DISCIPLINARIAS
 {nume: 41, acuerdo_id: 18,type:"", name:"Amonestación"},
 {nume: 42, acuerdo_id: 18,type:"", name:"Multa de cinco a cien umas"},
 {nume: 43, acuerdo_id: 18,type:"", name:"Auxilio de la fuerza pública"},
 {nume: 44, acuerdo_id: 18,type:"", name:"Arresto hasta por 36 horas"},

//SOBRESEIMIENTO FUERA DE AUDIENCIA
 {nume: 45, acuerdo_id: 19,type:"", name:"Por sobrevenir causal de improcedencia"},
 {nume: 46, acuerdo_id: 19,type:"", name:"Por desistimiento"},
 {nume: 47, acuerdo_id: 19,type:"", name:"Por muerte del actor"},
 {nume: 48, acuerdo_id: 19,type:"", name:"Por no demostrarse existencia del acto"},
 {nume: 49, acuerdo_id: 19,type:"", name:"Por inactividad procesal"},
 {nume: 50, acuerdo_id: 19,type:"", name:"Por haberse satisfecho las necesidades del actor"},
//SUSPENSION DEL PROCEDIMIENTO 
//INCIDENTES 
 {nume: 51, acuerdo_id: 21,type:"", name:"Acumulación de autos"},
 {nume: 52, acuerdo_id: 21,type:"", name:"Nulidad de notificaciones"},
 {nume: 53, acuerdo_id: 21,type:"", name:"Incompetencia en razón de territorio"},
 {nume: 54, acuerdo_id: 21,type:"", name:"Recusación por causa de impedimento"},
 {nume: 55, acuerdo_id: 21,type:"", name:"Falsedad de documentos"},
 {nume: 56, acuerdo_id: 21,type:"", name:"Incumplimiento de suspensión"},
 {nume: 57, acuerdo_id: 21,type:"", name:"Incidente innominado"},
//CONVENIOS  
{nume: 58, acuerdo_id: 22,type:"", name:"Presentación de convenio"},
{nume: 59, acuerdo_id: 22,type:"", name:"Ratificación de convenio"},
{nume: 60, acuerdo_id: 22,type:"", name:"Convenio pasado a categoría de cosa juzgada"},

//AUDIENCIA DE JUICIO 
{nume: 61, acuerdo_id: 23,type:"", name:"Desahogo de audiencia"},
{nume: 62, acuerdo_id: 23,type:"", name:"Desahogo parcial de audiencia"},
{nume: 63, acuerdo_id: 23,type:"", name:"Suspensión de audiencia"},
{nume: 64, acuerdo_id: 23,type:"", name:"Convenio en audiencia"},

//EJECUCIÓN DE SENTENCIA 
{nume: 65, acuerdo_id: 24,type:"", name:"Prevención cumplimiento diez días"},
{nume: 66, acuerdo_id: 24,type:"", name:"Segunda prevención cinco días"},
{nume: 67, acuerdo_id: 24,type:"", name:"Autorización prorroga cumplimiento"},
{nume: 68, acuerdo_id: 24,type:"", name:"Imposición de multa"},
{nume: 69, acuerdo_id: 24,type:"", name:"Cumplimiento defectuoso"},
{nume: 70, acuerdo_id: 24,type:"", name:"Vista al superior jerárquico por incumplimiento"},
{nume: 71, acuerdo_id: 24,type:"", name:"Vista al actor con informe de cumplimiento"},
{nume: 72, acuerdo_id: 24,type:"", name:"Cumplimiento y archivo"},
{nume: 73, acuerdo_id: 24,type:"", name:"Incumplimiento sentencia solicitud de destitucion"},
//RECURSOS
{nume: 74, acuerdo_id: 25,type:"", name:"Revisión"},
{nume: 75, acuerdo_id: 25,type:"", name:"Queja"},
{nume: 76, acuerdo_id: 25,type:"", name:"Apelación"},
{nume: 77, acuerdo_id: 25,type:"", name:"Reclamación"},
{nume: 78, acuerdo_id: 25,type:"", name:"Inconformidad"},
//AMPARO
{nume: 79, acuerdo_id: 26,type:"", name:"Indirecto"},
{nume: 80, acuerdo_id: 26,type:"", name:"Directo "},
{nume: 81, acuerdo_id: 26,type:"", name:"Acuerdos varios tramite amparo"},
//ARCHIVO DE EXPEDIENTE TOTALMENTE CONCLUIDO
{nume: 82, acuerdo_id: 27,type:"", name:"Cuantía del asunto"},

//OTRO


    
    ];

export enum AditionalOptionsId{
    RECURSO=0,
    INCIDENTE=0,
    AMPARO_INDIRECTO=0,
    ACUERDOS_FRECUENTES=0,
}

export const SubsentidoOptions=[
    
{nume: 1,  sentido_id: 3, name:"Incompetencia"},
{nume: 2,  sentido_id: 3, name:"Contra actos de autoridades de otros estados o de la administración pública federal"},
{nume: 3,  sentido_id: 3, name:"Contra actos legislativos"},
{nume: 4,  sentido_id: 3, name:"Contra sentencias de órganos jurisdiccionales del poder judicial"},
{nume: 5,  sentido_id: 3, name:"Contra laudos de autoridades del trabajo"},
{nume: 6,  sentido_id: 3, name:"Contra resoluciones de autoridades electorales"},
{nume: 7,  sentido_id: 3, name:"Contra resoluciones de autoridades agrarias"},
{nume: 8,  sentido_id: 3, name:"Contra actos que hayan sido resueltos por diverso proceso jurisdiccional por sentencia ejecutoria o que sea consecuencia del mismo (cosa juzgada)"},
{nume: 9,  sentido_id: 3, name:"Contra actos que no afecten los intereses del actor"},
{nume: 10,  sentido_id: 3, name:"Contra actos consentidos expresamente"},
{nume: 11,  sentido_id: 3, name:"Contra actos consentidos tácitamente"},
{nume: 12,  sentido_id: 3, name:"Contra actos que hayan sido materia de sentencia del tribunal de justicia administrativa (cosa juzgada)"},
{nume: 13,  sentido_id: 3, name:"Contra actos que sean materia de recurso o juicio pendiente de resolución de autoridad administrativa o ante el tribunal de justicia administrativa (litispendencia)"},
{nume: 14,  sentido_id: 3, name:"Contra actos conexos a otros que haya sido impugnado por algún recurso o medio de defensa diferente y la ley disponga que debe agotarse la misma vía"},
{nume: 15,  sentido_id: 3, name:"Contra actos inexistentes"},
{nume: 16,  sentido_id: 3, name:"Contra actos que no puedan surtir efectos legales o materiales por haber dejado de existir el objeto o materia del acto"},
{nume: 17,  sentido_id: 3, name:"Contra actos consumados irreparablemente"},
{nume: 18,  sentido_id: 3, name:"Contra la emisión de reglamentos"},
{nume: 19,  sentido_id: 3, name:"Contra actos de los cuales hayan cesados los efectos legales o materiales o no se puedan resarcir por haber dejado de existir el objeto o materia de los mismos"},
{nume: 20,  sentido_id: 3, name:"Por improcedencia que resulte de alguna otra disposición legal"},
//ADMISIÓN DEMANDA
{nume: 21,  sentido_id: 4, name:"Con suspensión concedida"},
{nume: 22,  sentido_id: 4, name:"Con suspensión negada"},
{nume: 23,  sentido_id: 4, name:"Sin solicitud de suspensión"},

//incidentes
{nume: 24,  sentido_id: 51, name:"Se admite"},
{nume: 25,  sentido_id: 51, name:"Se desecha"},

{nume: 26,  sentido_id: 52, name:"Se admite"},
{nume: 27,  sentido_id: 52, name:"Se desecha"},

{nume: 28,  sentido_id: 53, name:"Se admite"},
{nume: 29,  sentido_id: 53, name:"Se desecha"},

{nume: 30,  sentido_id: 54, name:"Se admite"},
{nume: 31,  sentido_id: 54, name:"Se desecha"},

{nume: 32,  sentido_id: 55, name:"Se admite"},
{nume: 33,  sentido_id: 55, name:"Se desecha"},

{nume: 34,  sentido_id: 56, name:"Se admite"},
{nume: 35,  sentido_id: 56, name:"Se desecha"},

{nume: 36,  sentido_id: 57, name:"Se admite"},
{nume: 37,  sentido_id: 57, name:"Se desecha"},

//CUMPLIMIENTO DEFECTUOSO 

{nume: 38,  sentido_id: 69, name:"Exceso"},
{nume: 39,  sentido_id: 69, name:"Defecto"},

//RECURSOS REVISION

{nume: 40,  sentido_id: 74, name:"Tenerlo por presentado y dar vista a la contraria"},
{nume: 41,  sentido_id: 74, name:"Contestando agravios y remitir a sala superior"},
{nume: 42,  sentido_id: 74, name:"No contestaron agravios y remitir a sala superior"},
{nume: 43,  sentido_id: 74, name:"Tenerlo por presentado y remitir a sala superior"},


//QUEJA 
{nume: 44,  sentido_id: 75, name:"Tenerla por presentada rendir informe y remitir a sala superior"},
//APELACIÓN 
{nume: 45,  sentido_id: 76, name:"Tenerlo por presentado y dar vista a la contraria"},
{nume: 46,  sentido_id: 76, name:"Contestando agravios y remitir a sala superior"},
{nume: 47,  sentido_id: 76, name:"No contestaron agravios y remitir a sala superior"},
//RECLAMACIÓN
{nume: 48,  sentido_id: 77, name:"Admisión a trámite y vista a la contraria"},
{nume: 49,  sentido_id: 77, name:"Desechamiento de plano"},
{nume: 50,  sentido_id: 77, name:"Contestación agravios y citar para sentencia"},
{nume: 51,  sentido_id: 77, name:"No contestación agravios y citar para sentencia"},
//INCONFORMIDAD 
{nume: 52,  sentido_id: 78, name:"Admisión a trámite y vista a la contraria"},
{nume: 53,  sentido_id: 78, name:"Desechamiento de plano"},
{nume: 54,  sentido_id: 78, name:"Contestación agravios y citar para sentencia"},
{nume: 55,  sentido_id: 78, name:"No contestación agravios y citar para sentencia"},
//aMPARO INDIRECTO
{nume: 56,  sentido_id: 79, name:"Rendir informe previo existencia del acto"},
{nume: 57,  sentido_id: 79, name:"Rendir informe previo inexistencia del acto"},
{nume: 58,  sentido_id: 79, name:"Rendir informe justificado existencia del acto"},
{nume: 59,  sentido_id: 79, name:"Rendir informe justificado inexistencia del acto"},
{nume: 60,  sentido_id: 79, name:"Recepción amparo negado"},
{nume: 61,  sentido_id: 79, name:"Recepción amparo sobreseído"},
{nume: 62,  sentido_id: 79, name:"Recepción amparo concedido"},
{nume: 63,  sentido_id: 79, name:"Informe de cumplimiento de ejecutoria de amparo indirecto"},
{nume: 64,  sentido_id: 79, name:"Informe de cumplimiento por exceso"},
{nume: 65,  sentido_id: 79, name:"Informe de cumplimiento por defecto"},
{nume: 66,  sentido_id: 79, name:"Acuerdo declara cumplida ejecutoria de amparo"},

//	DIRECTO 
{nume: 67,  sentido_id: 80, name:"Acuerdo recepción amparo directo rendir informe y emplazar a terceros interesados"},
{nume: 68,  sentido_id: 80, name:"Informe justificado existencia del acto"},
{nume: 69,  sentido_id: 80, name:"Informe justificado inexistencia del acto"},
{nume: 70,  sentido_id: 80, name:"Recepción amparo negado con expedientes originales"},
{nume: 71,  sentido_id: 80, name:"Recepción amparo negado sin expedientes originales"},
{nume: 72,  sentido_id: 80, name:"Recepción amparo sobreseído con expedientes originales"},
{nume: 73,  sentido_id: 80, name:"Recepción amparo sobreseído sin expedientes originales"},
{nume: 74,  sentido_id: 80, name:"Recepción amparo concedido con expedientes originales"},
{nume: 75,  sentido_id: 80, name:"Informe de cumplimiento de ejecutoria de amparo directo "},
{nume: 76,  sentido_id: 80, name:"Informe de cumplimiento por exceso"},
{nume: 77,  sentido_id: 80, name:"Informe de cumplimiento por defecto"},
{nume: 78,  sentido_id: 80, name:"Acuerdo declara cumplida ejecutoria de amparo"},


//ACUERDOS VARIOS TRAMITE AMPARO
{nume: 79,  sentido_id: 81, name:"Acusar recepción de expedientes originales"},
{nume: 80,  sentido_id: 81, name:"Acusar recepción de copias certificadas de expedientes"},
{nume: 81,  sentido_id: 81, name:"Solicitud de copias"},
{nume: 82,  sentido_id: 81, name:"Solicitud de informes"},
{nume: 83,  sentido_id: 81, name:"Admisión recurso de revisión"},
{nume: 84,  sentido_id: 81, name:"Admisión recurso de inconformidad"},
{nume: 85,  sentido_id: 81, name:"Remisión a la suprema corte"},
//CUANTÍA DEL ASUNTO
{nume: 86,  sentido_id: 82, name:"Con cuantía"},
{nume: 87,  sentido_id: 82, name:"Sin cuantía"},


];

export const DetalleOptions=[
    //INFORME DE CUMPLIMIENTO DE EJECUTORIA DE AMPARO INDIRECTO
{nume: 1,  subsentido_id: 63, name:"Dejar insubsistente acto reclamado"},
{nume: 2,  subsentido_id: 63, name:"Emitir acto conforme lineamientos"},
{nume: 3,  subsentido_id: 63, name:"Emitir acto con libertad de jurisdicción"},
    //INFORME DE CUMPLIMIENTO DE EJECUTORIA DE AMPARO DIRECTO 
{nume: 1,  subsentido_id: 75, name:"Dejar insubsistente acto reclamado"},
{nume: 2,  subsentido_id: 75, name:"Emitir acto conforme lineamientos"},
{nume: 3,  subsentido_id: 75, name:"Emitir acto con libertad de jurisdicción"},

//	CON CUANTÍA
//CAMPO DE TEXTO PARA INDICAR CUANTÍA



];

export const actoResolutionOption = [
    {nume: 1, name: 'Agua'},
    {nume: 2, name: 'Anuncios'},
    {nume: 3, name: 'Licencias de funcionamiento'},
    {nume: 4, name: 'Infracciones de tránsito'},
    {nume: 5, name: 'Predial'},
    {nume: 6, name: 'Resolución administrativa'},
    {nume: 7, name: 'Visitas de verificación'},
    {nume: 8, name: 'Concesiones de transporte'},
    {nume: 9, name: 'Multas o créditos fiscales'},
    {nume: 10, name: 'Responsabilidad de servidores públicos'},
    {nume: 11, name: 'Seguridad pública'},
    {nume: 12, name: 'Pensiones elementos policiales'},
    {nume: 13, name: 'Otros de seguridad social'},
    {nume: 14, name: 'Negativa ficta'},
    {nume: 15, name: 'Positiva/afirmativa ficta'},
    {nume: 16, name: 'Silencio administrativo'},
    {nume: 17, name: 'Otro acto administrativo'},


]

//promos para asuntos resueltos sin previo juicio
export const TypePromo = [
    {nume:1 , name:'Se acuerda la solicitud de la aplicación de medios alternos de solución de controversias'},
    {nume:2, name: 'Convenio sin demanda (medios alternos de solución de controversias)'}
]



























/***
 * 
 * contestacion de demanda
 * paso por sireproc 
 * nunca le aparecio al usuario
 * 
 * 
 * Promociones en el limbo no aparecen
 * 
 */