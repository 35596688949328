<div class="dialog-content-wrapper">
    <div mat-dialog-content>
        <h1>¡Aviso!</h1>
        <p><!-- De conformidad con lo dispuesto por el artículo 14 del Reglamento Interior del Tribunal de Justicia Administrativa del Estado de Guerrero, del 15 de julio al 3 de agosto de 2020 únicamente se proveerá y despachará respecto de las solicitudes de suspensión del acto impugnado que sean de trámite urgente. --></p>
        <button mat-raised-button
                color="accent"
                (click)="matDialogRef.close()"
                class="save-button"
                aria-label="SAVE">
            Aceptar
        </button>
    </div>
</div>