import { Component, Inject, ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { deterNotification, ResourcesProvider, UsersNotify,TypeSentidoSentence } from 'app/providers/resources.provider';
import { ExpedientProvider } from 'app/providers/expedient.provider';
import { UsersProvider } from "app/providers/users.provider";
import { MatStepper } from '@angular/material/stepper';
import * as moment from 'moment';
import { AppConfigService } from 'app/providers/data/app-config.service';
import { FileAnexo, TypeFile, OrganoJurisdiccional,AcuerdoSentidoOptions} from 'app/main/promotions/demanda-form/options.data.form';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
//MODULOS FIRMA ELECTRONICA
import {SelectFileSign,message,SelectPfx,OpenPfx,PKCS7creado,extFileUpload} from 'app/main/sign/common/functions/clienteArray';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TopicsService } from 'app/main/topics/topics.service';
import { DocumentProvider } from 'app/providers/documents.register.provider';
import { Todo } from 'app/main/agreements/todo/todo.model';
import { HttpResponse } from '@angular/common/http';
import {PartsService  } from 'app/main/parts/parts.service';
import { Part } from 'app/main/parts/part.model';

@Component({
    selector     : 'mail-compose',
    templateUrl  : './compose.component.html',
    styleUrls    : ['./compose.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MailComposeDialogComponent
{
    isLoading    :boolean = false;
    isAcuerdoNotification: boolean = false;
    isSentenciaNotification : boolean = false;

    isFisicNotification: boolean = false;
    isFisicNotificationAcue: boolean = false;
    isFisicNotificationEstrados: boolean = false;
    isNotificationSala: boolean = false;
    showExtraToFields: boolean;
    resolutionForm: FormGroup;
    expedientForm: FormGroup;
    transferForm: FormGroup;
    boletaForm: FormGroup;
    usersForm ; FormGroup;
    departments = [];
    allDepartaments = OrganoJurisdiccional.filter(dep => dep.nume < 14);
    users = [];
    constanciaEnvio={link:'#'};
    autorities = [];
    employees  = [];
    currentUser = { empe: '' }
    txtErrorNoti = '';
    agreementData: any;
    agreementDataLoaded: boolean = true;
    inSearching = false;
    employeeDepa: any;
    //txtTitleModal = 'Nueva notificación'
    txtTitleModal = '';
    //txtTitleUser: string = 'USUARIOS A NOTIFICAR POR VÍA ELECTRÓNICA';
    txtTitleUser: string = '';
    //txtDeterminacion = 'A NOTIFICAR';
    txtDeterminacion = '';
    //txtTitleDocs: string = 'Adjuntar PDF de determinación *';
    txtTitleDocs: string = '';
    txtErrorFile    :String = 'Debes adjuntar este documento es obligatorio'
    txtErrorFileadju:String = this.txtErrorFile;
    //files 
    typeFile = TypeFile;
    anexosArray = ArrayFiles;
    anexoMinutaNoti;
    anexoMinutaList;
    toUploadOk = false;
    currentFile: any;
    showMoreFileBtn = false;
    //date
    minDate: Date;
    maxDate: Date;
    minDateNoti: Date;
    //@ALAN VARIABLES FILTROS
    deterNotifiArray:any = [];
    deterNotifi: Observable<string[]>;
    //@END ALAN VARIABLES FILTROS
    //VARIABLES FIRMA ELECTRONICA
    message:string;
    pkcs7:string;
    activarBoton:boolean=false;
    filePFXselct:any;
    password: FormControl;
    extArchivoCargado:string='';
    anexosArrayPK:any = [];
    typeModal: string;
    usNotify: any = UsersNotify;
    juicio='';
    expediente='';
    //END VARIABLES FIRMA ELECTRONICA
    @ViewChild('adju') adju:ElementRef;
    @ViewChild('stepper') stepper:MatStepper;
    promotions: any[];
    documentsPromotion: Array<[]> = [];
    selectedPartes:any[]=[];
    partes:any[]=[];
    /**
     * Constructor
     *
     * @param {MatDialogRef<MailComposeDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        public matDialogRef: MatDialogRef<MailComposeDialogComponent>,
        private _formBuilder: FormBuilder, 
        private cd: ChangeDetectorRef,
        private _expedientServices: ExpedientProvider,
        private _usersService: UsersProvider,
        private _resourcesService: ResourcesProvider,
        private _settings: AppConfigService,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _matSnackBar: MatSnackBar,
        private _topicService: TopicsService,
        private _documentService: DocumentProvider,
        private _PartsService: PartsService,
    )
    {
        //data to dialog
     /*    this.setVariablesModal();
        // Set the defaults
        this.expedientForm = this.createExpedientForm();
        this.resolutionForm = this.createresolutionForm();
        this.usersForm = this.createUsersForm();
        this.showExtraToFields = true;
        //set date minimum
        const currentDate = moment();
        this.minDate = new Date(currentDate.year() , currentDate.month(), currentDate.date());
        this.maxDate = new Date(currentDate.year() , currentDate.month(), currentDate.date() + 4);
        this.deterNotifiArray = deterNotification;
        this.password = new FormControl(''); */
        //this.password.disable();
    }

    ngOnInit() {


        this.setVariablesModal();
        // Set the defaults
        this.expedientForm = this.createExpedientForm();
        this.resolutionForm = this.createresolutionForm();
        this.usersForm = this.createUsersForm();
        this.showExtraToFields = true;
        //set date minimum
        const currentDate = moment();
        this.minDate = new Date(currentDate.year() , currentDate.month(), currentDate.date());
        this.maxDate = new Date(currentDate.year() , currentDate.month(), currentDate.date() + 4);
        this.deterNotifiArray = deterNotification;
        this.password = new FormControl('');
        this.anexosArray = [new FileAnexo('anexoFile0')];
        this.anexoMinutaNoti = new FileAnexo('anexoFile');
        this.toUploadOk = false;
        this.currentFile = undefined;
        this.showMoreFileBtn = false;
        if(sessionStorage.getItem('usr') !== undefined) {
            this.currentUser = this._settings.getCurrentUser();
        }
        this.employeeDepa = this._settings.getDepaEmployee()
        this._getAllDepartments();
        console.log("agreementdata", this.agreementData, this.currentUser);

        if((this.isAcuerdoNotification && this.agreementData) || this.isFisicNotificationAcue){
            this.setInfoAgreement();
            this._getUsersAgreement(this.agreementData.nume);
            this._getPartes(this.agreementData.expe);
            
        }

        if((this.isSentenciaNotification && this.agreementData) || this.isFisicNotificationAcue){
           // this.setInfoAgreement();
            //this._getUsersAgreement(this.agreementData.nume);
            this.setInfoSentencia();
            this._getPartes(this.agreementData.expe);
            
        }

        if(this.isFisicNotificationEstrados) {
            this.fepu.setValidators([Validators.required]);
            this.fepu.updateValueAndValidity();
        }
        this._startChangesFilterDeterminacionNotificadas();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    setVariablesModal(){
        const _config = this._data;
        this.boletaForm = null;
        this.typeModal = _config.typeNotification;
        console.log("tipo de notificacion", _config)
        if(_config.typeNotification == 'boletinenlinea' || _config.typeNotification == 'boletintradicional'){
            this.isAcuerdoNotification = true;
            this.agreementData = _config.acuerdo;
            this.txtTitleModal = 'Notificación vía electrónica';
            this.txtTitleUser = 'USUARIOS A NOTIFICAR POR VÍA ELECTRÓNICA';
            this.txtDeterminacion = 'A NOTIFICAR';
            this.boletaForm = this.createBoletaForm();
            if(_config.acuerdo){
                this._documentService.getPromotionsByAgreement(_config.acuerdo.id).subscribe(
                    (result) => {
                        this.promotions = result["data"];
                        if(this.promotions.length > 0){
                            this._documentService
                            .getDocumentsUploaded(this.promotions[0].nume)
                            .subscribe(
                                (result) => {
                                    this.documentsPromotion = result["data"];
                                    console.log(
                                        "documentos de la promocion",
                                        this.documentsPromotion
                                    );
                                },
                                (error) => console.log("error get documents", error)
                            );
                        }
                    },
                    (error) => console.log("error get documents", error)
                );
            }

            if(_config.sentencia){
                console.log("hi im a sentence",_config.sentencia);
                this.isAcuerdoNotification = false;
                this.isSentenciaNotification = true;

                this._documentService.getPromotionsBySentence(_config.sentencia.id).subscribe(
                    (result) => {
                        this.promotions = result["data"];
                        if(this.promotions.length > 0){
                            this._documentService
                            .getDocumentsUploaded(this.promotions[0].nume)
                            .subscribe(
                                (result) => {
                                    this.documentsPromotion = result["data"];
                                    console.log(
                                        "documentos de la promocion",
                                        this.documentsPromotion
                                    );
                                },
                                (error) => console.log("error get documents", error)
                            );
                        }
                    },
                    (error) => console.log("error get documents", error)
                );
            } 
        }
        if(_config.typeNotification === 'fisicatradicional') {
            this.isFisicNotification = true;
            this.txtTitleModal = 'Nueva notificación física';
            this.txtTitleUser = 'USUARIOS NOTIFICADOS DE MANERA FÍSICA';
            this.txtDeterminacion = 'NOTIFICADA';
            this.txtTitleDocs = 'Adjuntar constancia de notificación *'
        }
       /* if(_config.typeNotification === 'acuerdo' || _config.typeNotification === 'acue-ofic'){
            this.txtTitleModal = 'Nueva notificación electrónica';
            this.isAcuerdoNotification = true;
            this.agreementData = _config.acuerdo;
        }else if(_config.typeNotification === 'fisica') {
            this.isFisicNotification = true;
            this.txtTitleModal = 'Nueva notificación física';
            this.txtTitleUser = 'USUARIOS NOTIFICADOS DE MANERA FÍSICA';
            this.txtDeterminacion = 'NOTIFICADA';
            this.txtTitleDocs = 'Adjuntar constancia de notificación *'
        }else if(_config.typeNotification === 'estrados') {
            this.isFisicNotificationEstrados = true;
            this.txtTitleModal = 'Nueva notificación de acuerdo por estrados';
            this.isAcuerdoNotification = true;
            this.txtTitleUser = 'FECHA A NOTIFICAR POR ESTRADOS';
            this.agreementData = _config.acuerdo;
        }else if(_config.typeNotification === 'acue-fisi') {
            this.txtTitleModal = 'Nueva notificación de acuerdo física';
            this.isFisicNotificationAcue = true;
            this.txtTitleUser = 'USUARIOS NOTIFICADOS DE MANERA FÍSICA';
            this.txtDeterminacion = 'NOTIFICADA';
            this.txtTitleDocs = 'Adjuntar constancia de notificación *'
            this.agreementData = _config.acuerdo;
        }else if(_config.typeNotification === 'acue-sala') {
            this.isNotificationSala = true;
            this.agreementData = _config.acuerdo;
            this.transferForm = this.createTransferForm();
        }*/
    }

    reloadVariablesModal(data){
       if(this._data.typeNotification == 'boletinenlinea' || this._data.typeNotification == 'boletintradicional'){
            this.isAcuerdoNotification = true;
            this.agreementData = data;
            this.txtTitleModal = 'Notificación por lista y boletin';
            this.txtTitleUser = 'USUARIOS A NOTIFICAR POR VÍA ELECTRÓNICA';
            this.txtDeterminacion = 'A NOTIFICAR';
            this.boletaForm = this.createBoletaForm();
            if(data){
                this._documentService.getPromotionsByAgreement(data.id).subscribe(
                    (result) => {
                        this.promotions = result["data"];
                        if(this.promotions.length > 0){
                            this._documentService
                            .getDocumentsUploaded(this.promotions[0].nume)
                            .subscribe(
                                (result) => {
                                    this.documentsPromotion = result["data"];
                                    console.log(
                                        "documentos de la promocion",
                                        this.documentsPromotion
                                    );
                                },
                                (error) => console.log("error get documents", error)
                            );
                        }
                    },
                    (error) => console.log("error get documents", error)
                );
            }
            this.expedientForm = this.createExpedientForm();
            this.resolutionForm = this.createresolutionForm();
            this.usersForm = this.createUsersForm();
        }
       
        this.expedientForm = this.createExpedientForm();
        this.resolutionForm = this.createresolutionForm();
        this.usersForm = this.createUsersForm();
        this._getAllDepartments();
        this._getUsers('')
        if((this.isAcuerdoNotification && this.agreementData) || this.isFisicNotificationAcue){
            this.setInfoAgreement();
            this._getUsersAgreement(this.agreementData.nume);
            this._getPartes(this.agreementData.expe);

        }

        if((this.isSentenciaNotification && this.agreementData) || this.isFisicNotificationAcue){
            this.setInfoSentencia();
            this._getPartes(this.agreementData.expe);

        }

        if(this.isFisicNotificationEstrados) {
            this.fepu.setValidators([Validators.required]);
            this.fepu.updateValueAndValidity();
        }
    }

    /**
     * Create compose form
     *
     * @returns {FormGroup}
     */
    createresolutionForm(): FormGroup
    {
        this.anexosArray = [new FileAnexo('anexoFile0')];
        return this._formBuilder.group({
            tipo  : ['', Validators.required],
            fecd  : ['', Validators.required],
            adju  : ['', Validators.required],
            fecn  : [''],
            time  : [''],
            anexos: [false],
            docuExpe: [[]],
            docuOfic: [''],
        });
    }

    createTransferForm(): FormGroup
    {
        const nume = this.agreementData.nume || '';
        this.anexosArray = [new FileAnexo('anexoFile0')];
        return this._formBuilder.group({
            nume  : [nume, Validators.required],
            depa  : ['', Validators.required],
            anexos: [false]
        });
    }

    createExpedientForm() : FormGroup {
        let une = { value: '', disabled: false };
        if((this.agreementData && this.isAcuerdoNotification) || this.isFisicNotificationAcue){
            const expe = this.agreementData.expe !== '' ? this.agreementData.expe : this.agreementData.une;
            une = { value: expe, disabled: true }
        }

        if((this.agreementData && this.isSentenciaNotification) || this.isFisicNotificationAcue){
            const expe = this.agreementData.expe !== '' ? this.agreementData.expe : this.agreementData.une;
            une = { value: expe, disabled: true }
        }
        let expeu = '';
        if(this.agreementData && this.isAcuerdoNotification){
            expeu = '';
        }

        let actor = '';
        if(this.agreementData && this.isAcuerdoNotification){
            actor =''; //this.agreementData.ciuddata.nomb+' '+this.agreementData.actor.pate+' '+this.agreementData.actor.mate;
        }
        return this._formBuilder.group({
            une   : [une, Validators.required],// Validators.pattern('([TJA]|[TCA]){3}[/]([SS]|[SR]){2}([ACHIOTZ]{1,2}|)[/]((\b)(JRAG|I|II|EXCJUS|QUEJA|RREC|RINC|RAP|REV)(\b)[/]|)[0-9]{1,5}[/][0-9]{4}')]],
            depa  : ['', Validators.required],
            part  : [actor, Validators.required],
            dema  : ['', Validators.required],
            terc  : [''],
            acue  : [une],
            expe_uni: [expeu],
        });
    }

    createBoletaForm(): FormGroup{
        return this._formBuilder.group({
            date_pub   : ['', [Validators.required]],// Validators.pattern('([TJA]|[TCA]){3}[/]([SS]|[SR]){2}([ACHIOTZ]{1,2}|)[/]((\b)(JRAG|I|II|EXCJUS|QUEJA|RREC|RINC|RAP|REV)(\b)[/]|)[0-9]{1,5}[/][0-9]{4}')]],
        });
    }

    createUsersForm (): FormGroup {

        return this._formBuilder.group({
            disp: [''],
            ciud: [[]],
            auth: [[]],
            usua: [[]],
            empl: [[]],
            acueusa: [[]],
            usnotify: ['']
        });
    }

    /**
     * Toggle extra to fields
     */
    toggleExtraToFields(): void
    {
        this.showExtraToFields = !this.showExtraToFields;
    }

    _getAllDepartments() {
        this._resourcesService.getDepartments().subscribe(
            (result) => {
                this.departments = result['data'].filter( element => element.nume === this._settings.getDepaEmployee())
                if((this.isAcuerdoNotification || this.isFisicNotificationAcue) && this.agreementData) {
                    this.expedientForm.patchValue({ depa: this.agreementData.empldata.data.depa.toString() })
                }else {
                    this.expedientForm.patchValue({ depa: this.employeeDepa.toString() })
                }
            },
            (error) => console.log('error get departaments', error)
        );
    }

    _getAgreementData(event){
        let searchText;
        if(event.target.value == ''){
            searchText = 'undefined';
        }else{
            searchText = event.target.value;
        }
        console.log("debug api call", searchText);

        this._resourcesService.getAgreementByExpediente(searchText).subscribe(
            (response)=>{
                console.log("response get agreement data", response['data']);
                if(response['data'].length > 0){

                    this.agreementDataLoaded = true;
                    this.agreementData = this._transformAgreements(response['data'][0]);
                    this.reloadVariablesModal(this.agreementData);
                }else{
                    this.agreementDataLoaded = false;
                }
            },(error)=>{
                console.log("error get agreement data", error);
            }
        )
    }

    _transformAgreements(todo){
        return new Todo(todo);
    }

    _getUsers(e) {
        if(!this.isAcuerdoNotification && this.expedientForm.value.une && this.expedientForm.value.depa){
            const data = {
                folio: this.expedientForm.value.une ,
                depa: this.expedientForm.value.depa
            }
            this.users      = [];
            this.autorities = [];
            this.employees  = [];
            this._usersService.getUserByExpediente(data).subscribe(
                (result) => {
                    result['data'].forEach(user => {
                        if(user.ciud !== 0){
                            this.users.push({ ...user.ciuddata.data.usuadata.data, type: user.tipo});
                        }else if(user.auto !== 0){
                            this.autorities.push({ ...user.autodata.data.usuadata.data, type: user.tipo});
                        }else if (Object.prototype.hasOwnProperty.call(user, 'empldata')) {
                            this.employees.push({ ...user.empldata.data.usuadata.data, type: user.tipo});
                        }
                        //this.fillParts(user);
                    });
                },
                (error) => console.log('error get users', error)
            );
        }
    }

    consoleForm(){
        console.log(this.expedientForm);
        console.log(this.expedientForm);
    }

    public debugForm(){
        console.log("debug form",this.findInvalidControlsRecursive( this.expedientForm));
        console.log("debug form",this.findInvalidControlsRecursive( this.usersForm));
        console.log("debug form",this.findInvalidControlsRecursive( this.resolutionForm));
        console.log("debug form",this.findInvalidControlsRecursive( this.boletaForm));


    }


    _getUsersAgreement(id_agreement){
        //const id_agreement = idthis.expedientForm.value.acue;
        this.users = [];
        this.autorities = [];
        this._usersService.getUserByAgreements(id_agreement).subscribe(
            (result) => {
                result['data'].forEach(user => {
                   // this.users.push({ ...user.usuadata.data, type: user.tipo});

                   // this.fillParts(user);
                });
            },
            (error) => console.log('error get users', error)
        );

        this._usersService.getAutoritiesByAgreements(id_agreement).subscribe(
            (result) => {
                result['data'].forEach(user => {
                   // this.autorities.push({ ...user.usuadata.data, type: user.tipo});
                   // this.fillParts(user);
                });
            },
            (error) => console.log('error get users', error)
        );

       
    }

    _getPartes(exp){
        //const id_agreement = idthis.expedientForm.value.acue;
       
        this.users = [];
        this.autorities = [];
        this._usersService.getPartesByExpediente(exp).subscribe(
            (result) => {

                console.log("partes debug",result);
               result['citizen'].forEach(user => {
                    this.users.push(user);

                   // this.fillParts(user);
                });
                
                result['authority'].forEach(user => {
                    this.autorities.push(user);

                   // this.fillParts(user);
                }); 
            },
            (error) => console.log('error get users', error)
        );



        this._PartsService.getByExp(exp).subscribe(p=>{
            console.log('debug partes back',p);
            this.partes=p['data'].map(p=>new Part(p));

            const actor_partes=this.partes.filter(p=>p.caracter==='actor').map(p=>p.persona.fullname).reduce((a,cv)=>`${a},${cv}`,'').substring(1);
            const demandados_partes=this.partes.filter(p=>p.caracter==='autoridadDemandada').map(p=>p.persona.fullname).reduce((a,cv)=>`${a},${cv}`,'').substring(1);
            const tercero_partes=this.partes.filter(p=>p.caracter==='tercero').map(p=>p.persona.fullname).reduce((a,cv)=>`${a},${cv}`,'').substring(1);
      

            this.expedientForm.patchValue({
                part: actor_partes,
                dema: demandados_partes,
                terc: tercero_partes
            });


          });

    }

    fillParts(reg){
        //TJA/SRA/II/JRAG/002/2020
        this.expedientForm.patchValue({
            part: reg.part,
            dema: reg.dema,
            terc: reg.terc
        });
    }

    setMinDateNoti(){
        this.minDateNoti = new Date( this.resolutionForm.get('fecd').value.year() ,  this.resolutionForm.get('fecd').value.month(),  this.resolutionForm.get('fecd').value.date());
    }

    setFile(inputFileName) {
        const reader = new FileReader();
        const fileUpload = this[`${ inputFileName }`].nativeElement;
        fileUpload.onchange = () => {   
            if(fileUpload.files && fileUpload.files.length) {
                const [file1] = fileUpload.files;
                if(!this.validTypeSizeFile(file1, inputFileName)) {
                    return
                }
                reader.readAsDataURL(file1);
                reader.onload = () => {
                this.resolutionForm.patchValue({
                    [`${ inputFileName }`]: file1
                });
                };
        
                this.cd.markForCheck();
            }
        };
        fileUpload.click();  
    }

    removeFile(inputFileName){
        this.resolutionForm.patchValue({
            [`${ inputFileName }`]: ''
        });
    }

    validTypeSizeFile(file, inputFileName) {
        if(inputFileName === 'adju'){
            this.txtErrorFileadju = this.txtErrorFile;
        }
        if(file.type !== 'application/pdf') {
            this[`txtErrorFile${ inputFileName }`] = 'El archivo debe ser en formato PDF';
            return false;
        }
        if(file.size > 10000000) {
            this[`txtErrorFile${ inputFileName }`] = 'El archivo debe ser de un tamaño no mayor a 10mb';
            return false;
        }
        return true;
    }

    setUser(user, event) {
        if(event.checked) {
            if(user.tipo === 'autoridad'){
                const toAdd = [...this.usersForm.value.auth, user.auto]
                this.usersForm.patchValue({auth: toAdd })
            }else if(user.tipo === 'empleado'){
                const toAdd = [...this.usersForm.value.empl, user.empe]
                this.usersForm.patchValue({empl: toAdd })
                console.log(this.usersForm.markAllTouched())
            }else { //ciudadano
                const toAdd = [...this.usersForm.value.ciud, user.ciud]
                this.usersForm.patchValue({ciud: toAdd })
            }
            const toAddU = [...this.usersForm.value.acueusa, user.nume]
            this.usersForm.patchValue({acueusa: toAddU })
        }else {
            if(user.tipo === 'autoridad'){
                const withOutElement = this.usersForm.value.auth.filter(e => e !== user.auto)
                this.usersForm.patchValue({auth: withOutElement})
            }else if(user.tipo === 'empleado'){
                const withOutElement = this.usersForm.value.empl.filter(e => e !== user.empe)
                this.usersForm.patchValue({emple: withOutElement})
            }else {
                const withOutElement = this.usersForm.value.ciud.filter(e => e !== user.ciud)
                this.usersForm.patchValue({ciud: withOutElement})
            }
            const withOutElementU = this.usersForm.value.acueusa.filter(e => e !== user.nume)
            this.usersForm.patchValue({acueusa: withOutElementU })
            
        }
    }

    setDocuExpe(docu, event){
        if(event.checked){
            const toAdd = [...this.resolutionForm.value.docuExpe, docu.nume]
            this.resolutionForm.patchValue({docuExpe: toAdd});
        }else{
            const withOutElement = this.resolutionForm.value.docuExpe.filter(e=>e !== docu.nume)
            this.resolutionForm.patchValue({docuExpe: withOutElement});
        }
    }

    setDocuOfic(docu, event){
        if(event.checked){
            this.resolutionForm.patchValue({docuOfic: docu});
        }else{
            this.resolutionForm.patchValue({docuOfic: ''});
        }
        console.log("datos del formulario documentos", this.resolutionForm.value);
    }

    sendMail() {
        console.log("guardado de los datos");
        this.isLoading = true;
        this.txtErrorNoti = '';
        if(!this.isSentenciaNotification && (this.isAcuerdoNotification || this.isFisicNotificationAcue)) { //notificaciones de acuerdos
            const notificationForm = this.isFisicNotificationAcue ? this._getFormData() : this._getFormDataAgreement();
            notificationForm.forEach((x, i) => console.log(i ,'->', x))
            this._expedientServices.sendNotificationAgreement(notificationForm, this.agreementData.nume).subscribe(
                (result) => {
                    this._requestSuccess(result);
                },
                (error) => {
                    this._requestError(error)
                }
            );
        }else if(this.isSentenciaNotification) { //notificacion a sala
            const notificationForm = this. _getFormDataAgreement();
            notificationForm.forEach((x, i) => console.log(i ,'->', x))
            this._expedientServices.sendNotificationSentence(notificationForm, this.agreementData.id).subscribe(
                (result) => {
                    this._requestSuccess(result);
                },
                (error) => {
                    this._requestError(error);
                }
            );
        }else { //notificacion normal o fisica
            const notificationForm = this._getFormData();
            notificationForm.forEach((x, i) => console.log(i ,'->', x))
            this._expedientServices.sendNotification(notificationForm).subscribe(
                (result) => {
                    this._requestSuccess(result);
                },
                (error) => {
                    this._requestError(error);
                }
            );
        }
        
    
    }

    //@ALAN FILTRO DETERMINACION NOTIFICADA
    _startChangesFilterDeterminacionNotificadas(){
        this.deterNotifi = this.resolutionForm.controls.tipo.valueChanges.pipe(
            startWith(''),
            map((valueInput) => this._filterDeterminacionNotificadas(valueInput))
        );
    }

    private _filterDeterminacionNotificadas(name): string[]{
        const filterValue = name.toLowerCase();
        return this.deterNotifiArray.filter(element => element.name.toLowerCase().includes(filterValue));
    }
    //@END ALAN FILTRO DETERMINACION NOTIFICADA

    //@ALAN GET ID DETERMINACION NOTIFICADA
  _getDeterNotifiId(){
    let tempDeterNotifi = this.resolutionForm.value.tipo;
      let tempResult:string;

      this.deterNotifiArray.forEach(element => {
        let flaj = false;
        if(element.name == tempDeterNotifi && flaj == false){
          tempResult = element.nume
        }
      });

      return tempResult;
  }
  //@END ALAN GET ID DETERMINACION NOTIFICADA

    _requestSuccess(result) {
        this.txtErrorFileadju = this.txtErrorFile;
        this.isLoading = false;
        this.matDialogRef.close(['send', 'Revisa tu historial de notificaciones para consultar las constancias'])
        console.log(result);
    }

    _requestError(error) {
        this.isLoading = false;
        this.txtErrorNoti = this._settings._getMsjError(error);
        console.log(error)
    }

    setInfoAgreement(){
        const expe = this.agreementData.expe !== '' ? this.agreementData.expe : this.agreementData.une;
        this.expedientForm.patchValue({
            une   : expe,
            acue  : expe,
        });

        this._topicService.getOfficialExpedient(expe).subscribe(
            (response)=>{
               // this.expedientForm.get('dema').setValue(response['data']['us_auth_name'][0]);
               // this.expedientForm.get('terc').setValue(response['data']['us_terc_name'][0] != undefined ? response['data']['us_terc_name'][0] : '');
                this.juicio=response['data']['judg'];
                this.expediente=response['data']['expe'];
                console.log("obtener los datos del expediente", response);
            },(error)=>{

            }
        )

        if(!this.isFisicNotificationAcue){
            this.adjun.setValidators(null);
            this.adjun.updateValueAndValidity();
        }
        
        this.resolutionForm.patchValue({
            tipo  : 'Acuerdo',
            fecd  : this.agreementData.date,
            adju  : this.agreementData.docu,
        });

    }


    setInfoSentencia(){
        const expe = this.agreementData.expe !== '' ? this.agreementData.expe : this.agreementData.une;
        this.expedientForm.patchValue({
            une   : expe,
            acue  : expe,
        });

        this._topicService.getOfficialExpedient(expe).subscribe(
            (response)=>{
               // this.expedientForm.get('dema').setValue(response['data']['us_auth_name'][0]);
               // this.expedientForm.get('terc').setValue(response['data']['us_terc_name'][0] != undefined ? response['data']['us_terc_name'][0] : '');
                this.juicio=response['data']['judg'];
                this.expediente=response['data']['expe'];
                console.log("obtener los datos del expediente", response);
            },(error)=>{

            }
        )

        if(!this.isFisicNotificationAcue){
            this.adjun.setValidators(null);
            this.adjun.updateValueAndValidity();
        }
        
      
        let tipo=this.agreementData.tyse=='int'?"Sentencia interlocutoria":"Sentencia definitiva";

        this.resolutionForm.patchValue({
            tipo  : tipo,
            fecd  : this.agreementData.uptd,
            adju  : this.agreementData.docu,
        });

    }

    _getFormData() {
        const notificationForm = new FormData();
        notificationForm.append('depa', this.expedientForm.value.depa);
        notificationForm.append('part', this.expedientForm.value.part);
        notificationForm.append('dema', this.expedientForm.value.dema);
        notificationForm.append('terc', this.expedientForm.value.terc);
        //notificationForm.append('tipo', this.resolutionForm.value.tipo) //=> elegir texto: acuerdo o resolución,
        notificationForm.append('tipo', this._getDeterNotifiId());
        notificationForm.append('adju', this.resolutionForm.value.adju) //=> Documento emitido,
        notificationForm.append('empl', this.currentUser.empe) //=> 'employee_id' Obtener id de empleado,
        notificationForm.append(`fisi`, (this.isFisicNotification || this.isFisicNotificationAcue) ? '1':'0');
        notificationForm.append(`file`, this.toUploadOk ? '1' : '0');
        notificationForm.append('fecd', this.resolutionForm.value.fecd) //=> fecha en que fue dictado,
        notificationForm.append('expe_uni', this.expedientForm.value.expe_uni);
        notificationForm.append('usnotify', this.usersForm.value.usnotify);
        //type notification
        notificationForm.append('nottype', this._data.typeNotification);
        let une = this.expedientForm.value.une;
        let kien = 'ambos';
        if(this.isFisicNotificationAcue){
            kien = 'ninguno';
            une = this.agreementData.expe;
        }
        notificationForm.append('une',  une) //=> 'folio',
        //set files in form data
        this.anexosArray.forEach((item, index) => {
            if(item.file !== undefined && item.desc !== '') {
            notificationForm.append(`dcan[${ index }]`, item.file);
            notificationForm.append(`dsdc[${ index }]`, item.desc);
            notificationForm.append(`tpdc[${ index }]`, 'file');
            notificationForm.append(`dcpa[${ index }]`, item.numPages);
            }
        });

        this.anexosArrayPK.forEach((item, index)=>{
            if(item !== undefined){
                notificationForm.append(`dcanEncode[${index}]`, item, 'pdfdeprueba.pdf')
            }
        });
        
        if (this.isFisicNotification || this.isFisicNotificationAcue) {
            notificationForm.append(`doc`, this.usersForm.value.usua.join(','));
            notificationForm.append(`usua`, this.usersForm.value.acueusa.join(','));
            let timeStr = this.resolutionForm.value.time.split(' ')[0],
                time    = moment(timeStr, 'hh:mm');

            const date = this.resolutionForm.value.fecn.set({
                hour:   time.get('hour'),
                minute: time.get('minute'),
                second: time.get('second')
            });

            notificationForm.append('feno', date.format("YYYY-MM-DD HH:mm:ss"));
        }else {
            const ciudadanos = this.usersForm.value.ciud;
            const autoridades = this.usersForm.value.auth;
            const employees   = this.usersForm.value.empl;

            if(ciudadanos.length > 0) {
                kien = 'ciudadano'
                for (const key in ciudadanos) {
                    if (ciudadanos.hasOwnProperty(key)) {
                        notificationForm.append(`ciud[${key}]`, ciudadanos[key])
                    }
                }
            }
            if(autoridades.length > 0) {  
                kien = 'autoridad'    
                for (const key in autoridades) {
                    if (autoridades.hasOwnProperty(key)) {
                        notificationForm.append(`auth[${key}]`, autoridades[key])
                    }
                }
            }

            if(employees.length > 0) {  
                kien = 'empleado'    
                for (const key in employees) {
                    if (autoridades.hasOwnProperty(key)) {
                        notificationForm.append(`ases[${key}]`, employees[key])
                    }
                }
            }
            console.log(kien)
            if(ciudadanos.length > 0 && autoridades.length > 0) {
                kien = 'ambos'  
            }
        }

        //ESTRADOS
        let como = 'electronica';
        if(this.isFisicNotificationAcue) {
            como = 'fisica';
        }
        notificationForm.append('como', como);
        notificationForm.append('kien', kien);

        return notificationForm;
    }

    _getFormDataSala(){
        console.log('form data sala');
        const notificationForm = new FormData();
        notificationForm.append('depa', this.transferForm.value.depa);
        notificationForm.append(`file`, this.toUploadOk ? '1' : '0');
         //set files in form data
        this.anexosArray.forEach((item, index) => {
            if(item.file !== undefined && item.desc !== '') {
            notificationForm.append(`dcan[${ index }]`, item.file);
            notificationForm.append(`dsdc[${ index }]`, item.desc);
            notificationForm.append(`tpdc[${ index }]`, 'file');
            notificationForm.append(`dcpa[${ index }]`, item.numPages);
            }
        });

        return notificationForm;
    }

    _getFormDataAgreement(){
        console.log('form data agreemement', this.expedientForm.value)
        const notificationForm = new FormData();
        notificationForm.append('part', this.expedientForm.value.part);
        notificationForm.append('dema', this.expedientForm.value.dema);
        notificationForm.append('terc', this.expedientForm.value.terc);
        notificationForm.append('tipo', this.resolutionForm.value.tipo);
        notificationForm.append('docuExpe', this.resolutionForm.value.docuExpe);
        notificationForm.append('docuOfic', this.resolutionForm.value.docuOfic);
        notificationForm.append('empl', this.currentUser.empe);
        notificationForm.append(`file`, this.toUploadOk ? '1' : '0');
        notificationForm.append('expe_uni', this.expedientForm.value.expe_uni);
        if(this.boletaForm){
            notificationForm.append('date_pub', this.boletaForm.value.date_pub);
        }
        //notificationForm.append('dcanEncodeNoti', this.anexoMinutaNoti.file);
        //notificationForm.append('dcanEncodeNotiDesc', this.anexoMinutaNoti.desc);
        //notificationForm.append('dcanEncodeNotiDescPag', this.anexoMinutaNoti.numPages);
        if(this.anexoMinutaList){
            notificationForm.append('dcanEncodeList', this.anexoMinutaList.file);
            notificationForm.append('dcanEncodeListDesc', this.anexoMinutaList.desc);
            notificationForm.append('dcanEncodeListDescPag', this.anexoMinutaList.numPages);
        }

        //set files in form data
        this.anexosArray.forEach((item, index) => {
            if(item.file !== undefined && item.desc !== '') {
            notificationForm.append(`dcan[${ index }]`, item.file);
            notificationForm.append(`dsdc[${ index }]`, item.desc);
            notificationForm.append(`tpdc[${ index }]`, 'file');
            notificationForm.append(`dcpa[${ index }]`, item.numPages);
            }
        });

        //type notification
        notificationForm.append('nottype', this._data.typeNotification);
        /*this.anexosArrayPK.forEach((item, index)=>{
            if(item !== undefined){
                notificationForm.append(`dcanEncode[${index}]`, item, 'pdfdeprueba.pdf')
            }
        });*/

        const ciudadanos = this.usersForm.value.ciud;
        const autoridades = this.usersForm.value.auth;
        let kien = 'ninguno';
        if(ciudadanos.length > 0) {
            kien = 'ciudadano'  
            for (const key in ciudadanos) {
                if (ciudadanos.hasOwnProperty(key)) {
                    notificationForm.append(`ciud[${key}]`, ciudadanos[key])
                }
            }
        }
        if(autoridades.length > 0) {
            kien = 'autoridad'
            for (const key in autoridades) {
                if (autoridades.hasOwnProperty(key)) {
                    notificationForm.append(`auth[${key}]`, autoridades[key])
                }
            }
        }
        if(ciudadanos.length > 0 && autoridades.length > 0) {
            kien = 'ambos'
        }
        notificationForm.append('kien', kien)

        //ESTRADOS
        let como = 'electronica';
        if(this._data.typeNotification === 'lista') {
            como = 'estrados';
            notificationForm.append('publ', this.boletaForm.value.date_pub)
            notificationForm.append(`doc`, this.usersForm.value.usua.join(','));
           // notificationForm.append(`usua`, this.usersForm.value.acueusa.join(','));
        }
        notificationForm.append('como', como)

        return notificationForm;
    }

    /** Methods Files */
    setFiler(inputFileName, index) {
        const reader = new FileReader();
        const fileUpload = document.querySelector(`#${ inputFileName }`) as HTMLInputElement;
        fileUpload.onchange = () => {   
            if(fileUpload.files && fileUpload.files.length) {
            const file = fileUpload.files[0];
            if(!this.validTypeSizeFiler(file, inputFileName)) {
                return
            }
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.currentFile = file;
                this.addFile(inputFileName, index);
            };
            }
        };
        fileUpload.click();  
    }

    validTypeSizeFiler(file, inputFileName) {
        const input = this.anexosArray.find(anexo => anexo.name === inputFileName);
        input.error = true;
        input.txtError = '';
        if(file.type !== 'application/pdf') {
            input.txtError = 'El archivo debe ser en formato PDF';
            return false;
        }
        if(file.size > 10000000) {
            input.txtError = 'El archivo debe ser de un tamaño no mayor a 10mb';
            return false;
        }
        input.txtError = 'Debes ingresar una descripción de documento';
        return true;
    }

    addFile(inputFileName, index) {
        const typeFile = document.querySelector(`#${ inputFileName }desc`) as HTMLInputElement;
        let file = this.anexosArray.find( file => file.name === inputFileName)
        file.file= this.currentFile,
        file.desc= typeFile.value,
        file.txtBtnUp= this.currentFile.name.substr(0,15),
        file.size= this._bytesToSize(this.currentFile.size)
        this._getNumPages(this.currentFile, file);
        this.validFieldsUpload();
        this.resetInputFile(inputFileName)
    }

    addDescFile(inputFileName, index){
        const typeFile = document.querySelector(`#${ inputFileName }desc`) as HTMLInputElement;
        if( typeFile.value !== '') {
            this.anexosArray[index].desc = typeFile.value;
            this.anexosArray[index].upload = true;
        }else {
            this.resetInputFile(inputFileName);
            // this.removeFile(index);
            this.anexosArray[index].upload = false;
        }
        //this.validFieldsUpload();
    }

    /**
     * ANEXO MINUTA NOTIFICACION
     * @param valueSelected 
     * @param index 
     */

     addMinutaNotiFile(inputFileName){
        const typeFile = document.querySelector(`#${ inputFileName }desc`) as HTMLInputElement;
        console.log("type file anexo noti", typeFile);
        if( typeFile.value !== '') {
            this.anexoMinutaNoti.desc = typeFile.value;
            this.anexoMinutaNoti.upload = true;
        }else {
            this.resetInputFile(inputFileName);
            // this.removeFile(index);
            this.anexoMinutaNoti.upload = false;
        }
        //this.validFieldsUpload();
    }

    /** Methods Files */
    setFilerMinutaNoti(inputFileName) {
        const reader = new FileReader();
        const fileUpload = document.querySelector(`#${ inputFileName }`) as HTMLInputElement;
        fileUpload.onchange = () => {   
            if(fileUpload.files && fileUpload.files.length) {
            const file = fileUpload.files[0];
            if(!this.validTypeSizeFileMinutaNoti(file)) {
                return
            }
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.currentFile = file;
                this.addFileMinutaNoti(inputFileName);
            };
            }
        };
        fileUpload.click();  
    }

    addFileMinutaNoti(inputFileName) {
        const typeFile = document.querySelector(`#${ inputFileName }desc`) as HTMLInputElement;
        let file = this.anexoMinutaNoti;
        file.file= this.currentFile,
        file.desc= typeFile.value,
        file.txtBtnUp= this.currentFile.name.substr(0,15);
        file.size= this._bytesToSize(this.currentFile.size);
        file.type = 'minuta';
        this._getNumPages(this.currentFile, file);
        this.validFieldsUpload();
        this.resetInputFile(inputFileName)
    }

    
    validTypeSizeFileMinutaNoti(file) {
        if(file.type !== 'application/pdf') {
        this.anexoMinutaNoti.txtError = 'El archivo debe ser en formato PDF';
        return false;
        }
        if(file.size > 10000000) {
        this.anexoMinutaNoti.txtError = 'El archivo debe ser de un tamaño no mayor a 10mb';
        return false;
        }
        return true;
    }

    /**
     * ANEXO MINUTA LISTA
     * @param valueSelected 
     * @param index 
     */
    addMinutaListFile(inputFileName){
        const typeFile = document.querySelector(`#${ inputFileName }desc`) as HTMLInputElement;
        if( typeFile.value !== '') {
            this.anexoMinutaList.desc = typeFile.value;
            this.anexoMinutaList.upload = true;
        }else {
            this.resetInputFile(inputFileName);
            // this.removeFile(index);
            this.anexoMinutaList.upload = false;
        }
        //this.validFieldsUpload();
    }

    /** Methods Files */
    setFilerMinutaList(inputFileName) {
        const reader = new FileReader();
        const fileUpload = document.querySelector(`#${ inputFileName }`) as HTMLInputElement;
        fileUpload.onchange = () => {   
            if(fileUpload.files && fileUpload.files.length) {
            const file = fileUpload.files[0];
            if(!this.validTypeSizeFileMinutaList(file)) {
                return
            }
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.currentFile = file;
                this.addFileMinutaList(inputFileName);
            };
            }
        };
        fileUpload.click();  
    }

    addFileMinutaList(inputFileName) {
        const typeFile = document.querySelector(`#${ inputFileName }desc`) as HTMLInputElement;
        let file = this.anexoMinutaList;
        file.file= this.currentFile,
        file.desc= typeFile.value,
        file.txtBtnUp= this.currentFile.name.substr(0,15),
        file.size= this._bytesToSize(this.currentFile.size)
        this._getNumPages(this.currentFile, file);
        this.validFieldsUpload();
        this.resetInputFile(inputFileName)
    }

    
    validTypeSizeFileMinutaList(file) {
        if(file.type !== 'application/pdf') {
        this.anexoMinutaList.txtError = 'El archivo debe ser en formato PDF';
        return false;
        }
        if(file.size > 10000000) {
        this.anexoMinutaList.txtError = 'El archivo debe ser de un tamaño no mayor a 10mb';
        return false;
        }
        return true;
    }

    setTypeFile(valueSelected, index){
        this.anexosArray[index].type = valueSelected.value;
    }
    
    removeFiler(indexFile){
        if(this.anexosArray.length > 1 ) {
            this.anexosArray.splice(indexFile, 1);
            this.validFieldsUpload();
        }else {
            this.anexosArray[indexFile] = new FileAnexo(`anexoFile${ indexFile }`); 
            this.showMoreFileBtn = false;
            this.resolutionForm.patchValue({ anexos: false });
        }
    }

    validFieldsUpload() {
        const anexosValid = this.anexosArray.filter(f => f.file && f.desc !== '');
        const anexosNotValid = this.anexosArray.filter(f => f.file && f.desc === '');
        if(anexosValid.length > 0 && anexosNotValid.length === 0){
            this.resolutionForm.patchValue({ anexos: true });
            this.toUploadOk = true;
            anexosValid.forEach(anexo => { anexo.error = false })
            this.showMoreFileBtn = true;
        }else {
            this.toUploadOk = false;
            this.resolutionForm.patchValue({ anexos: false });
            anexosNotValid.forEach(anexo => { anexo.error = true })
        }
    }

    addOther(){
        this.showMoreFileBtn = false;
        const id = this.anexosArray.length;
        if(id < 5){
            this.anexosArray.push(new FileAnexo(`anexoFile${ id }`));
        }
    }

    resetInputFile(inputFileName){
        const file = document.querySelector(`#${ inputFileName }`) as HTMLInputElement;
        this.currentFile = undefined;
        file.value = '';
    }

    _bytesToSize(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    _getNumPages(file, fileObject) {
        //read num pages pdf
        let count = '';
        var read = new FileReader();
        read.readAsBinaryString(file);
        read.onload = function(){
            count = read.result.toString().match(/\/Type[\s]*\/Page[^s]/g).length.toString();
            fileObject.numPages = count;
        }
    }
      /**END FILES */

        /**
     * Add user
     *
     * @param {MatChipInputEvent} event
     */
    addUser(event: MatChipInputEvent): void
    {
        const input = event.input;
        const value = event.value;

        // Add user
        if ( value )
        {
            this.usersForm.value.usua.push(value);
        }

        // Reset the input value
        if ( input )
        {
            input.value = '';
        }
    }

    /**
     *  Filer Datepicker
     * @param date 
     */
    //datepicker filter
    myFilterDatePicker = (date: moment.Moment | null): boolean => {
        const day = date !== null ? date.day() : new Date().getDay();
        // Prevent Saturday and Sunday from being selected.
        return day !== 0 && day !== 6;
    };

    validAddMoreDays(date, tipo){
        const day = date.day();
        let addDays = 0;
        if(tipo == 'min') {
            if(day === 5){
                addDays = 3
            }
            if(day === 6){
                addDays = 2
            }
            addDays = 1
        }else {
            if(day > 1 && day < 6){
                addDays = 6
            }
            if(day === 6){
                addDays = 5
            }
            addDays = 4
        }
        //[matDatepickerFilter]="myFilterDatePicker"
        return date.date() + addDays;
    }

    validUsersForm() {
        const selecionados= this.selectedPartes.length as number;
        return !(selecionados >0);
        return false;
        const validCiud = (this.usersForm.value.ciud.length === 0);
        const validAuth = (this.usersForm.value.auth.length === 0);
        const validEmpl = (this.usersForm.value.empl.length === 0);
        const validUser = (this.usersForm.value.usua.length === 0);
        if(!this.isFisicNotificationEstrados && (validCiud && validAuth && validUser && validEmpl)){
            return true;
        }else if(this.isFisicNotificationEstrados && this.fepu.invalid){
            return true;
        }
        return false;
    }

    validInactiveSendBtn() {
        if(!this.isNotificationSala) {
            return (this.resolutionForm.invalid || this.expedientForm.invalid || (this.boletaForm && this.boletaForm.invalid) ||this.validUsersForm());
        }else {
            return (this.transferForm.invalid);
        }
    }

    /**
     * Remove user
     *
     * @param user
     */
    removeUser(user): void
    {
        const index = this.usersForm.value.usua.indexOf(user);

        if ( index >= 0 )
        {
            this.usersForm.value.usua.splice(index, 1);
        }
    }

    get adjun() {
        return this.resolutionForm.get('adju') as FormControl;
    }

    get fepu() {
        return this.usersForm.get('disp') as FormControl;
    }

    //FIRMA ELECTRONICA
    SeleccionarPfx(filePFX: any){
        /*this.anexosArray.forEach(element => {
        if(element.file !== undefined){
            SelectFileSign(element.file).then((isValidFile)=>{
            if(isValidFile){
                this.message = message;
                this.extArchivoCargado = extFileUpload;
                this.activarBoton=SelectPfx(filePFX);
                if(this.activarBoton){
                    this.password.enable();
                    this.filePFXselct=filePFX;
                }
            }
            });
        }
        });*/
        SelectFileSign(this.anexoMinutaNoti.file).then((isValidFile)=>{
            if(isValidFile){
                this.message = message;
                this.extArchivoCargado = extFileUpload;
                this.activarBoton=SelectPfx(filePFX);
                if(this.activarBoton){
                    this.password.enable();
                    this.filePFXselct=filePFX;
                }
            }
        })
    }

    firmar(){
        if((this.isAcuerdoNotification && this.isFisicNotificationAcue)||this.isSentenciaNotification ){
            this.sendMail();

            OpenPfx(this.filePFXselct, this.password.value).then((isCreatePKCS7)=>{
                if(isCreatePKCS7){               
                    this.pkcs7=PKCS7creado;
                    this.saveTextAsFile(this.pkcs7);
                }
            },(error)=>{
                this._matSnackBar.open('La contraseña no coincide con la firma electronica', 'OK', {
                verticalPosition: 'bottom',
                horizontalPosition: 'right',
                duration        : 3000
                });
            });
        }else{
            this.sendMail();
        }
    }

    saveTextAsFile(element:any)
        {         
            this.anexosArrayPK = [];
            console.log("file encripted sign", element);
            element.forEach(e => {
            var fecha=new Date();
        
            let textToWrite = e;
            let textFileAsBlob = new Blob([textToWrite], {type:'text/plain'});
            //let fileNameToSaveAs = "pkcs7_" + fecha.valueOf() + "."+this.extArchivoCargado;
            let fileNameToSaveAs = "pkcs7_" + fecha.valueOf() + "."+this.extArchivoCargado;
            /*var byteCharacters = atob(e);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {type: "application/pdf"});
            var fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');*/

            let blobAsFile = new File([textFileAsBlob], fileNameToSaveAs);
            /*let tempItemAnexo = {
            blobAsFile,
            }*/
            //this.anexosArrayPK.push(blobAsFile);
            //this.anexoMinutaNoti.file = blobAsFile;
            //saveAs(textFileAsBlob, fileNameToSaveAs);
            });
            this.sendMail();
            //this.sendData();
        }
    //END FIRMA ELECTRONICA

    public findInvalidControlsRecursive(formToInvestigate:FormGroup|FormArray):string[] {
        var invalidControls:string[] = [];
        let recursiveFunc = (form:FormGroup|FormArray) => {
          Object.keys(form.controls).forEach(field => { 
            const control = form.get(field);
            if (control.invalid) invalidControls.push(field);
            if (control instanceof FormGroup) {
              recursiveFunc(control);
            } else if (control instanceof FormArray) {
              recursiveFunc(control);
            }        
          });
        }
        recursiveFunc(formToInvestigate);
        return invalidControls;
      }



downloadFile(type,e){


    e.stopPropagation();
    e.preventDefault();

    let data=[];

    let user=this.users[0];
    data.push({key:'expediente',value:this.agreementData.expe});
    data.push({key:'juicio',value:this.juicio});
    data.push({key:'sala',value:this.expedientForm.value.depa});
    data.push({key:'actor',value:`${user.nomb} ${user.pate} ${user.mate}`});
   
    if (this.isAcuerdoNotification) {
        data.push({key:'sintesis',value:AcuerdoSentidoOptions.filter(sentido=>sentido.nume==this.agreementData.sentido_acuerdo)[0].name});
        data.push({key:'fecha_acuerdo',value:this.agreementData.date});
        data.push({key:'tipo_doc',value:'acuerdo'});
    }

    if (this.isSentenciaNotification) {
        data.push({key:'sintesis',value:TypeSentidoSentence.filter(sentido=>sentido.nume==this.agreementData.tysent)[0].name});
        data.push({key:'fecha_acuerdo',value:this.agreementData.uptd});
        data.push({key:'tipo_doc',value:'sentencia'});
    }


    data.push({key:'actuary',value:this.currentUser.empe});
    
    console.log('data constancias',data);
    
    this._documentService.getConstanciesPreviewFiles(type,data).subscribe(
        (response: HttpResponse<Blob>)=>{

            let filename: string = "constancia.pdf"
            let binaryData = [];
            binaryData.push(response.body);
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
            downloadLink.setAttribute('download', filename);
            document.body.appendChild(downloadLink);
            downloadLink.click();


        }, (error)=>{
          console.log("error topics", error);
        }
      )
}

onSelectedParts(partes){
    this.selectedPartes=partes;
  }

}

const ArrayFiles = [
    new FileAnexo('anexoFile0'),
]; 


