import { DataSource } from '@angular/cdk/collections';
import { Component, OnInit, Input ,OnChanges,SimpleChanges,ViewChild, Output, EventEmitter, Inject} from '@angular/core';
import { AppConfigService } from "app/providers/data/app-config.service";
import { FormArray, FormBuilder, FormGroup,FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IParte,IPersonaFisica,IAutoridad,IPersonaMoral, IParteForms, Part } from '../part.model';
import { MatSelectionListChange } from '@angular/material/list';

import {PartsService  } from '../parts.service';



@Component({
  selector: 'parts-selector',
  templateUrl: './parts-selector.component.html',
  styleUrls: ['./parts-selector.component.scss']
})


export class PartsSelectorComponent implements OnInit , OnChanges{

  
_expediente:string;
_expedient_id:number;

selectedActor:IParte;


@Input() expediente:string;
@Input() expedient_id:number;
@Input() partesExpediente:Array<IParte>;
@Output() getSelectedPartsEvent = new EventEmitter<Array<IParte>>();

partes:Array<IParte>=[];
forms:IParteForms[];


public get actores(){
 return this.partes.filter(p=>p.caracter==='actor');
}

public get autoridadesDemandadas(){
  return this.partes.filter(p=>p.caracter==='autoridadDemandada');
 }

 public get terceros(){
  return this.partes.filter(p=>p.caracter==='tercero');
 }





  constructor( private _settings: AppConfigService,private fb: FormBuilder,private _matSnackBar: MatSnackBar,public dialog: MatDialog,private _PartsService: PartsService,) { 


   /*  this.partes=[
      {persona:{id:1,fullname:"Jesus Ramirez Hernadez",curp:"sdfgsfdg",tipo:"persona fisica",domicilio_procesal:"jesus@gmail.com"},caracter:"actor"},
      {persona:{id:2,fullname:"Francisco Ramirez Hernandez",curp:"dsfgsdfg",tipo:"persona fisica",domicilio_procesal:"francisco@gmail.com"},caracter:"actor"},
      {persona:{id:3,fullname:"Gatos unidos y asociados S.A.",rfc:"dfgdfgdfg",tipo:"persona juridica",domicilio_procesal:"gatos@gmail.com"},caracter:"tercero"},
      {persona:{id:4,fullname:"Ayuntamiento de Cuautitlan Izcalli",tipo:"autoridad",domicilio_procesal:"izcalli@gmail.com"},caracter:"autoridadDemandada"},
      {persona:{id:5,fullname:"Policia de Cuautitlan Izcalli",tipo:"autoridad",domicilio_procesal:"policia@gmail.com"},caracter:"autoridadDemandada"},
      {persona:{id:6,fullname:"Perros unidos y asociados S.A.",rfc:"asdasdasd",tipo:"persona juridica",domicilio_procesal:"perros@gmail.com"},caracter:"tercero"},
    ];
 */
/*     if (this.expediente) {
      this._expediente=this.expediente;
    }

    if (!this._expediente) {
      return;
    }
     */


  }

  ngOnInit(): void {
    
    if (!this.partesExpediente) {
      return;
    }

    this.partes=this.partesExpediente;
    


  }
  ngOnDestroy(): void
    {
      
    }     


  ngOnChanges(changes: SimpleChanges) {

    if (!this.partesExpediente) {
      return;
    }

    this.partes=this.partesExpediente;
    
  }

  

  onItemSelect(event:MatSelectionListChange){
    console.log(event.source.selectedOptions.selected.map(p=>p.value));
    this.getSelectedPartsEvent.emit(event.source.selectedOptions.selected.map(p=>p.value));

}



}

