import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';


@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
    constructor(public _auth: AuthService,
                public _router: Router) { }

    canActivate() {
        if (!this._auth.isAutenticated()) {
            console.log('not user logged to');
            this._router.navigate(['/pages/auth/login']);
            return false;
        }
        return true;
    }

    canViewEmployee() {
        console.log('employeee')
        if (!this._auth.isAutenticated()) {
            if(this._auth.isEmployee()){
                this._router.navigate(['/']);
                return false;
            }
            this._router.navigate(['/inicio']);
            return false
        }
        return true;
    }
}