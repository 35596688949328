import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'app/providers/data/app-config.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TopicsService {
  urlTopic:string = '';
  urlMags:string = '';
  urlOfficExpe: string = '';
  onNewTopicForm: Subject<any>;
  onTopicSelected: Subject<any>;

  constructor(private _httpClient: HttpClient, private _settings: AppConfigService) { 
    this.urlTopic = this._settings.getUrlApi() + 'topics';
    this.urlMags = this._settings.getUrlApi() + 'employees/';
    this.urlOfficExpe = this._settings.getUrlApi() + 'officialdom-expedient?expe=';
    this.onNewTopicForm = new Subject();
    this.onTopicSelected = new Subject();
  }

  getTopicsByDepartment(nume){
    let optionHeaders = this._settings.getHeadersMultipart();
    return this._httpClient.get(this.urlTopic+'/'+nume, optionHeaders);
  }

  getEmployeeMags(id){
    let optionHeaders = this._settings.getHeadersJson();
    return this._httpClient.get(this.urlMags+id+'/magistrates', optionHeaders);
  }

  getOfficialExpedient(data){
    let optionHeaders = this._settings.getHeadersJson();
    return this._httpClient.get(this.urlOfficExpe+data, optionHeaders);
  }

  saveTopic(data){
    let optionHeaders = this._settings.getHeadersMultipart();
    return this._httpClient.post(this.urlTopic, data,optionHeaders);
  }
}
