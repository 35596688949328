<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ txtTitleModal }}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>

        <div class="vertical-stepper-wrapper">
        <mat-vertical-stepper  linear="false" #stepper id="stepper" name="stepper" *ngIf="!isNotificationSala">
            <mat-step [stepControl]="expedientForm">
                <form fxLayout="column" [formGroup]="expedientForm" >
                    <ng-template matStepLabel>EXPEDIENTE</ng-template>
                    <div fxFlex="1 0 auto" fxLayout="column">
                        <!--FORMULARIO NOTIFICACION ELECTRONICA EN JUICIO EN LINEA-->
                        <!--numero de expediente automatico-->
                        <mat-form-field appearance="outline" fxFlex="50" *ngIf="isAcuerdoNotification || isFisicNotificationAcue">
                            <mat-label>Número de Expediente</mat-label>
                            <input matInput formControlName="acue" (blur)="_getAgreementData($event)" [disabled]="true" required>
                            <mat-error>Este campo es obligatorio</mat-error>
                        </mat-form-field>
                        <mat-error *ngIf="!agreementDataLoaded">No existen registros con el número de expediente proporcionado</mat-error>

                        <mat-form-field appearance="outline" fxFlex="50" *ngIf="!isAcuerdoNotification && !isFisicNotificationAcue">
                            <mat-label>Número de Expediente</mat-label>
                            <input matInput formControlName="une" (blur)="_getUsers($event)"  required>
                            <mat-error *ngIf="expedientForm.get('une').hasError('required')">
                                Este campo es obligatorio</mat-error>
                            <mat-error *ngIf="!expedientForm.get('une').hasError('required') &&
                                            expedientForm.get('une').hasError('pattern')">
                                Debes ingresar un formato de número de expediente correcto y sin espacios,ejemplos: 2012-123 ó 123/2012
                            </mat-error>
                        </mat-form-field>

            
                        <mat-form-field  appearance="outline" fxFlex="50">
                            <mat-select formControlName="depa" required (selectionChange)="_getUsers($event)">
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option *ngFor="let dep of departments" value="{{ dep.nume }}">{{ dep.depa }}</mat-option>
                            </mat-select>
                            <mat-label>
                                Órgano jurisdiccional
                            </mat-label>
                            <mat-error>Debes seleccionar un sala regional o sala superior</mat-error>
                        </mat-form-field>
                        <!--END FORMULARIO NOTIFICACION ELECTRONICA EN JUICIO EN LINEA-->
                        <h3>Partes Procesales</h3>
                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Nombre Actor</mat-label>
                            <input matInput formControlName="part"  required>
                            <mat-error>Debes ingresar un nombre de actor</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Nombre de la Autoridad Demandada</mat-label>
                            <input matInput formControlName="dema"  required>
                            <mat-error>Debes ingresar un nombre de la autoridad demandada</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Nombre Tercero Perjudicado</mat-label>
                            <input matInput formControlName="terc">
                            <mat-error>Debes ingresar un nombre de tercero perjudicado</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button mat-raised-button matStepperNext type="button" color="accent" [disabled]="!expedientForm.valid">
                            Continuar
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <form fxLayout="column" [formGroup]="usersForm" >
                <ng-template matStepLabel>{{ txtTitleUser }}</ng-template>
                    <div fxLayout="column" fxLayoutAlign="start start" style="overflow-x: auto;" *ngIf="!isFisicNotification && !isFisicNotificationAcue && !isFisicNotificationEstrados && typeModal != 'lista'">

                        <parts-selector [partesExpediente]="partes"  (getSelectedPartsEvent)="onSelectedParts($event)"></parts-selector>
                       <!--  <div appearance="outline" class="pr-4" fxFlex *ngIf="users.length > 0">
                            <h3>Actores</h3>
                            <section>
                                <mat-checkbox *ngFor="let user of users" style="margin-right: 15px;" (change)="setUser(user, $event)" >
                                    {{user.nomb}} {{user.pate}} {{user.mate}} <span class="labels-gray" *ngIf="!isAcuerdoNotification">({{user.type}})</span>
                                </mat-checkbox>
                            </section>
                        </div>

                        <div appearance="outline" class="pr-4" fxFlex *ngIf="autorities.length > 0">
                            <h3>Autoridades</h3>
                            <mat-checkbox *ngFor="let user of autorities" style="margin-right: 15px;" (change)="setUser(user, $event)" >
                                {{user.nomb}} {{user.pate}} {{user.mate}} <span class="labels-gray" *ngIf="!isAcuerdoNotification">({{user.type}})</span>
                            </mat-checkbox>
                        </div>

                        <div appearance="outline" class="pr-4" fxFlex *ngIf="employees.length > 0">
                            <h3>Empleados (Asesor Ciudadano)</h3>
                            <mat-checkbox *ngFor="let employee of employees" style="margin-right: 15px;" (change)="setUser(employee, $event)" >
                                {{employee.nomb}} {{employee.pate}} {{employee.mate}} <span class="labels-gray" *ngIf="!isAcuerdoNotification">({{employee.type}})</span>
                            </mat-checkbox>
                        </div>

                        <div class="py-24" *ngIf="users.length == 0 && autorities.length == 0 && employees.length == 0">
                            <mat-hint>
                                No se encontraron actores suscritos a este expediente, verifique los datos
                            </mat-hint>
                        </div> -->

                        
                    </div>
                    <mat-form-field appearance="outline" class="w-100-p" *ngIf="isFisicNotification || isFisicNotificationAcue ">
                        <mat-label>
                            Usuarios notificados
                        </mat-label>
                        <mat-select multiple formControlName="usnotify" required>
                            <mat-option *ngFor="let item of usNotify" value="{{item.name}}">{{item.name}}</mat-option>
                        </mat-select>
                        <mat-error>
                            Selecciona una opción
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always" class="w-100-p" *ngIf="isFisicNotification || isFisicNotificationAcue || typeModal == 'lista'">

                        <mat-label>Usuarios</mat-label>

                        <mat-chip-list #categoryList name="usua" formControlName="usua">

                            <mat-chip *ngFor="let user of usersForm.value.usua"
                                        [removable]="true" (removed)="removeUser(user)">
                                {{user}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>

                            <input [matChipInputFor]="categoryList"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addUser($event)"/>

                        </mat-chip-list>
                        <mat-hint>Pulsa ENTER para agregar </mat-hint>
                    </mat-form-field>

                    <div fxFlex="1 0 auto" fxLayout="column" style="overflow-x: auto;" *ngIf="isFisicNotificationEstrados">
                        <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
                            <mat-label>Fecha de publicación</mat-label>
                            <input matInput 
                                    [min]="minDate" [max]="maxDate"
                                    [matDatepicker]="startDatePickerP"
                                    formControlName="disp" 
                                    (focus)="startDatePickerP.open()" required>
                            <mat-datepicker-toggle matSuffix [for]="startDatePickerP"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePickerP></mat-datepicker>
                            <mat-error>Debes ingresar una fecha</mat-error>
                        </mat-form-field>
                    </div>
                    
                </form>
                <div fxLayout="row" fxLayoutAlign="space-evenly center" >
                    <button mat-raised-button matStepperNext type="button" color="accent" class="mt-40" [disabled]="validUsersForm()">Continuar</button>
                </div>
            </mat-step>
            <mat-step [stepControl]="resolutionForm" >
                <form  fxLayout="column" [formGroup]="resolutionForm" >
                    <ng-template matStepLabel>DETERMINACIÓN {{ txtDeterminacion }}</ng-template>
                    <div fxFlex="1 0 auto" fxLayout="column">
                        
                        <mat-form-field appearance="outline" fxFlex>
                            <!--<mat-select formControlName="tipo" required >
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option value="acuerdo">Acuerdo/auto</mat-option>
                                <mat-option value="resolución">Resolución interlocutoria</mat-option>
                                <mat-option value="sentencia">Sentencia</mat-option>
                            </mat-select>-->
                        <!--     <mat-select>
                                <mat-option value="acuerdo">Acuerdo</mat-option>
                                <mat-option value="interlocutoria">Sentencia interlocutoria</mat-option>
                                <mat-option value="definitiva">Sentencia definitiva</mat-option>
                            </mat-select> -->
                            <mat-label>
                                Determinación {{ txtDeterminacion.toLowerCase() }}
                            </mat-label>
                            <input matInput formControlName="tipo" [matAutocomplete]="tipo" required>
                                <mat-autocomplete autoActiveFirstOption #tipo="matAutocomplete">
                                    <mat-option *ngFor="let option of deterNotifi | async" [value]="option.name">
                                        {{option.name}}
                                    </mat-option>
                                </mat-autocomplete>
                            <mat-error>Debes seleccionar una determinación {{ txtDeterminacion.toLowerCase() }}</mat-error>
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
                            <mat-label>Fecha en que fue dictado</mat-label>
                            <input matInput [matDatepicker]="startDatePicker" formControlName="fecd" (focus)="startDatePicker.open()" required (dateChange)="setMinDateNoti()">
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                            <mat-error>Debes ingresar una fecha</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="mr-sm-12" fxFlex *ngIf="isFisicNotification || isFisicNotificationAcue">
                            <mat-label>Fecha de notificación</mat-label>
                            <input matInput [min]="minDateNoti" [matDatepicker]="startDatePicker2" formControlName="fecn" (focus)="startDatePicker2.open()" required>
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker2"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker2></mat-datepicker>
                            <mat-error>Debes ingresar una fecha</mat-error>
                        </mat-form-field>

                        <mat-label *ngIf="isFisicNotification">Hora de notificación</mat-label>
                        <mat-form-field appearance="outline" *ngIf="isFisicNotification || isFisicNotificationAcue">
                            <input matInput placeholder="Simple placeholder" style="display: none;">
                            <ngx-timepicker-field [controlOnly]="true" [format]="24" formControlName="time"></ngx-timepicker-field>
                        </mat-form-field>

                        <div *ngIf="(isSentenciaNotification || isAcuerdoNotification || isFisicNotificationAcue) && agreementData">
                            <a mat-stroked-button color="accent" href="{{agreementData.docu }}" target="_blank" >
                                Ver documento
                            </a>
                        </div>


                        <div class="file-uploader" fxFlex="1 0 auto" *ngIf="(!isSentenciaNotification || !isAcuerdoNotification) && isFisicNotification" fxLayout="column" fxLayoutAlign="space-envenly start">
                            <mat-label>{{ txtTitleDocs }}</mat-label>
                            <input type="file" #adju id="adju" name="adju" style="display:none;" accept="application/pdf" required/>
                            <button mat-raised-button color="info" (click)="setFile('adju')">  
                                <mat-icon>backup</mat-icon>  
                                <span> Elegir archivo</span>
                            </button> 
                            <div class="attachment-list" *ngIf="resolutionForm.value.adju !== null && resolutionForm.value.adju.name !== undefined">
                                <div class="attachment" fxLayout="row" fxLayoutAlign="space-between center">
                                    <div>
                                        <span class="filename">{{ resolutionForm.value.adju.name }}</span>
                                        <!--span class="size ml-4">(12 Kb)</spandaut-->
                                    </div>
                                    <button mat-icon-button aria-label="Delete attachment">
                                        <mat-icon class="s-16" (click)="removeFile('adju')" tooltip="Quitar archivo">close</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <mat-error *ngIf="resolutionForm.get('adju').hasError('required')" class="error">{{ txtErrorFileadju }}</mat-error>
                        </div>

                        <mat-label class="mt-16 mb-2">ANEXOS </mat-label>
                        <!--ANEXO MINUTA NOTIFICACION-->

                        
                        <div *ngIf="isAcuerdoNotification || isSentenciaNotification ">
                            <button  mat-stroked-button color="accent" (click)="downloadFile('mail',$event)">
                                <mat-icon>assignment</mat-icon>
                                Generar constancia de envió de aviso electrónico
                            </button>
                           
                        
                            <!-- <p>Firmar Constancia de envió de aviso electrónico</p> -->
                            <div class="file-uploader my-12" >
                                <!-- <div class="columna1">
                                    <div class="file-uploader my-16">
                                        <div fxLayout="row" fxFlex="100">
                                            <mat-form-field  appearance="outline" fxFlex class="pr-4">
                                                <input matInput type="text" 
                                                    id="{{anexoMinutaNoti.name}}desc" 
                                                    name="{{anexoMinutaNoti.name}}desc" 
                                                    (keyup)="addMinutaNotiFile(anexoMinutaNoti.name)" 
                                                    maxlength="100"
                                                    required />
                                                <mat-label>
                                                    Minuta aviso de notificación electrónica
                                                </mat-label>
                                                <mat-hint align="start">Número de fojas: {{ anexoMinutaNoti.numPages }}</mat-hint>
                                                <mat-hint align="end">{{ anexoMinutaNoti.desc.length}} / 100</mat-hint>
                                            </mat-form-field> 
                                        </div>
                                        <div fxLayout="row" fxFlex="100">
                                            <mat-error *ngIf="anexoMinutaNoti.error" class="error">{{anexoMinutaNoti.txtError}}</mat-error>
                                        </div>
                                    </div>
                                </div> -->

                                <!-- <div fxLayout="column" fxLayoutAlign="start center" class="mt-20" fxFlex>
                                    <input type="file" id="{{anexoMinutaNoti.name}}" name="{{anexoMinutaNoti.name}}" style="display:none;" accept="application/pdf" required/>
                                    <button mat-stroked-button color="info" (click)="setFilerMinutaNoti(anexoMinutaNoti.name)" [disabled]="!anexoMinutaNoti.upload">  
                                        <mat-icon>backup</mat-icon>
                                        <span> {{ anexoMinutaNoti.txtBtnUp }}</span>
                                    </button> 
                                </div> -->
                            </div>
                            <!--ANEXO MINUTA LISTA-->
                            <div class="file-uploader my-12"  *ngIf="anexoMinutaList">
                                <div class="columna1">
                                    <div class="file-uploader my-16">
                                        <div fxLayout="row" fxFlex="100">
                                            <mat-form-field  appearance="outline" fxFlex class="pr-4">
                                                <input matInput type="text" 
                                                    id="{{anexoMinutaList.name}}desc" 
                                                    name="{{anexoMinutaList.name}}desc" 
                                                    (keyup)="addMinutaListFile(anexoMinutaList.name)" 
                                                    maxlength="100"
                                                    required />
                                                <mat-label>
                                                    Minuta lista y boletín
                                                </mat-label>
                                                <mat-hint align="start">Número de fojas: {{ anexoMinutaList.numPages }}</mat-hint>
                                                <mat-hint align="end">{{ anexoMinutaList.desc.length}} / 100</mat-hint>
                                            </mat-form-field> 
                                        </div>
                                        <div fxLayout="row" fxFlex="100">
                                            <mat-error *ngIf="anexoMinutaList.error" class="error">{{anexoMinutaList.txtError}}</mat-error>
                                        </div>
                                    </div>
                                </div>

                                <div fxLayout="column" fxLayoutAlign="start center" class="mt-20" fxFlex>
                                    <input type="file" id="{{anexoMinutaList.name}}" name="{{anexoMinutaList.name}}" style="display:none;" accept="application/pdf" required/>
                                    <button mat-stroked-button color="info" (click)="setFilerMinutaList(anexoMinutaList.name)" [disabled]="!anexoMinutaList.upload">  
                                        <mat-icon>backup</mat-icon>
                                        <span> {{ anexoMinutaList.txtBtnUp }}</span>
                                    </button> 
                                </div>
                            </div>
                            <!--FIRMA ELECTRONICA-->
                            <div class="row" fxLayoutAlign="center center">
                                <div class="col-sm-3">
                                    Cargar archivo FIREL <i class="icono fas fa-upload"></i> 
                                    <input type="file"  id="pfx" accept=".pfx"  class="upload-input"  (change)="SeleccionarPfx($event.target)" />
                                    <div id="divArchivo" class="textoArchivo"></div>
                                    <div id="divCargaFirel"  class="cargaFirel">                        
                                    </div>
                                    <br>
                                    <mat-form-field appearance="outline" fxFill>
                                        <mat-label>Escriba su contraseña:</mat-label>
                                        <input matInput type="password" [formControl]="password"/>
                        
                                    </mat-form-field>
                                </div>
                            </div>
                            <!--ANEXOS-->
                            <p>OTROS ANEXOS</p>
                            <div *ngIf="agreementData && agreementData.oficDocUrl">
                                <p>Oficio del acuerdo</p>
                                <mat-checkbox style="margin-right: 15px;" (change)="setDocuOfic(agreementData.oficDocUrl, $event)"> 
                                    <a mat-button color="accent" href="{{agreementData.oficDoc }}" target="_blank">Ver documento</a>
                                </mat-checkbox>
                            </div>
                            <p>Archivos de promoción</p>
                            <mat-checkbox *ngFor="let item of documentsPromotion" style="margin-right: 15px;" (change)="setDocuExpe(item, $event)" >
                                {{item.dscr}} <a mat-button color="accent" href="{{item.docu }}" target="_blank">Ver documento</a>
                            </mat-checkbox>
                        </div>
                        <div class="file-uploader my-16" *ngFor="let file of anexosArray; let in=index;">
                            <div class="columna1">
                                <div class="file-uploader my-16">
                                    <div fxLayout="row" fxFlex="100">
                                        <mat-form-field  appearance="outline" fxFlex class="pr-4">
                                            <input matInput type="text" 
                                                id="{{file.name}}desc" 
                                                name="{{file.name}}desc" 
                                                (keyup)="addDescFile(file.name, in)" 
                                                maxlength="100"
                                                required />
                                            <mat-label>
                                                Descripción del documento
                                            </mat-label>
                                            <mat-hint align="start">Número de fojas: {{ file.numPages }}</mat-hint>
                                            <mat-hint align="end">{{ file.desc.length}} / 100</mat-hint>
                                        </mat-form-field> 
                                    </div>
                                    <div fxLayout="row" fxFlex="100">
                                        <mat-error *ngIf="file.error" class="error">{{file.txtError}}</mat-error>
                                    </div>
                                </div>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start center" class="mt-20" fxFlex>
                                <input type="file" id="{{file.name}}" name="{{file.name}}" style="display:none;" accept="application/pdf" required/>
                                <button mat-stroked-button color="info" (click)="setFiler(file.name, in)" [disabled]="!file.upload">  
                                    <mat-icon>backup</mat-icon>
                                    <span> {{ file.txtBtnUp }}</span>
                                </button> 
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start center" class="mt-20" fxFlex>
                                <button mat-mini-fab aria-label="Delete attachment" *ngIf="file.file">
                                    <mat-icon (click)="removeFiler(in)" tooltip="Quitar archivo">close</mat-icon>
                                    <!--span>Quitar archivo</span-->
                                </button>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex *ngIf="showMoreFileBtn && anexosArray.length < 5">
                            <button mat-stroked-button color="accent" (click)="addOther()" [disabled]="anexosArray.length === 5">  
                                <mat-icon>add_circle_outline</mat-icon>  
                                <span> Subir otro archivo</span>
                            </button> 
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="boletaForm">
                        <button mat-raised-button matStepperNext type="button" color="accent" [disabled]="!resolutionForm.valid">
                            Continuar
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step *ngIf="boletaForm" [stepControl]="boletaForm">
                <ng-template matStepLabel>BOLETÍN JURISDICCIONAL</ng-template>
                <div fxFlex="1 0 auto" fxLayout="column">
                   <div *ngIf="typeModal == 'electronicaenlinea'">PROGRAMA LA FECHA DE PUBLICACIÓN<!-- “PROGRAMA LA FECHA DE PUBLICACIÓN DE LA NOTIFICACIÓN POR LISTA Y BOLETÍN ELECTRÓNICO, PARA EL SUPUESTO CONTEMPLADO EN LA FRACCIÓN VI DE ARTÍCULO 33 DEL CÓDIGO DE PROCEDIMIENTOS DE JUSTICIA ADMINISTRATIVA DEL ESTADO DE GUERRERO NÚMERO 763”. --></div>
                    <form fxLayout="column" [formGroup]="boletaForm">
                        <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
                            <mat-label>Fecha de publicación</mat-label>
                            <input matInput 
                                    [min]="minDate"
                                    [matDatepicker]="startDatePickerP"
                                    formControlName="date_pub" 
                                    (focus)="startDatePickerP.open()" required>
                            <mat-datepicker-toggle matSuffix [for]="startDatePickerP"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePickerP></mat-datepicker>
                            <mat-error>Debes ingresar una fecha</mat-error>
                        </mat-form-field>
                    </form>
                    <button  mat-stroked-button color="accent" (click)="downloadFile('boletin',$event)">
                        <mat-icon>assignment</mat-icon>
                        Generar constancia de publicación en boletín electrónico
                    </button>

                </div>
            </mat-step>
        </mat-vertical-stepper>
        <mat-vertical-stepper linear="false" #stepper id="stepper" name="stepper" *ngIf="isNotificationSala">
            <mat-step [stepControl]="transferForm">
                <form fxLayout="column" [formGroup]="transferForm" >
                    <ng-template matStepLabel>EXPEDIENTE</ng-template>
                    <div fxFlex="1 0 auto" fxLayout="column">
                        <mat-form-field  appearance="outline" fxFlex="50">
                            <mat-select formControlName="depa" required>
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option *ngFor="let dep of allDepartaments" value="{{ dep.nume }}">{{ dep.depa }}</mat-option>
                            </mat-select>
                            <mat-label>
                                Sala Superior o Sala Regional
                            </mat-label>
                            <mat-error>Debes seleccionar un sal regional o sala superior</mat-error>
                        </mat-form-field>

                        <mat-label class="mt-16 mb-2">Anexos </mat-label>
                        <div class="file-uploader my-16" *ngFor="let file of anexosArray; let in=index;">
                            <div class="columna1">
                                <div class="file-uploader my-16">
                                    <div fxLayout="row" fxFlex="100">
                                        <mat-form-field  appearance="outline" fxFlex class="pr-4">
                                            <input matInput type="text" 
                                                id="{{file.name}}desc" 
                                                name="{{file.name}}desc" 
                                                (keyup)="addDescFile(file.name, in)" 
                                                maxlength="100"
                                                required />
                                            <mat-label>
                                                Descripción del documento
                                            </mat-label>
                                            <mat-hint align="start">Número de fojas: {{ file.numPages }}</mat-hint>
                                            <mat-hint align="end">{{ file.desc.length}} / 100</mat-hint>
                                        </mat-form-field> 
                                    </div>
                                    <div fxLayout="row" fxFlex="100">
                                        <mat-error *ngIf="file.error" class="error">{{file.txtError}}</mat-error>
                                    </div>
                                </div>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start center" class="mt-20" fxFlex>
                                <input type="file" id="{{file.name}}" name="{{file.name}}" style="display:none;" accept="application/pdf" required/>
                                <button mat-stroked-button color="info" (click)="setFiler(file.name, in)" [disabled]="!file.upload">  
                                    <mat-icon>backup</mat-icon>
                                    <span> {{ file.txtBtnUp }}</span>
                                </button> 
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start center" class="mt-20" fxFlex>
                                <button mat-mini-fab aria-label="Delete attachment" *ngIf="file.file">
                                    <mat-icon (click)="removeFile(in)" tooltip="Quitar archivo">close</mat-icon>
                                    <!--span>Quitar archivo</span-->
                                </button>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex *ngIf="showMoreFileBtn && anexosArray.length < 5">
                            <button mat-stroked-button color="accent" (click)="addOther()" [disabled]="anexosArray.length === 5">  
                                <mat-icon>add_circle_outline</mat-icon>  
                                <span> Subir otro archivo</span>
                            </button> 
                        </div>
                        <div class="row" fxLayoutAlign="center center">
                            <div class="col-sm-3">
                                Cargar archivo FIREL <i class="icono fas fa-upload"></i> 
                                <input type="file" id="pfx" accept=".pfx"  class="upload-input"  (change)="SeleccionarPfx($event.target)" />
                                <div id="divArchivo" class="textoArchivo"></div>
                                <div id="divCargaFirel"  class="cargaFirel">                        
                                </div>
                                <br>
                                <mat-form-field appearance="outline" fxFill>
                                    <mat-label>Escriba su contraseña:</mat-label>
                                    <input matInput type="password" [formControl]="password"/>
                    
                                </mat-form-field>
                            </div>
                            
                        </div>
                    </div>
                </form>
            </mat-step>

        </mat-vertical-stepper>
        </div>
    </div>
    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <!--<button (click)="consoleForm()">
                ver formulario
            </button>-->
            <button mat-raised-button
                    color="accent"
                    (click)="firmar()"
                    class="save-button"
                    [disabled]="validInactiveSendBtn() || (password.value == '' && isAcuerdoNotification) || isLoading"
                    aria-label="SAVE">
                Enviar
                <div fxLayout="column" fxLayoutAlign="center center " *ngIf="isLoading">
                    <mat-progress-spinner
                        diameter="25"
                        mode="indeterminate"
                        [value]="valueLoading">
                    </mat-progress-spinner>
                </div>
            </button>
           <!--  <button (click)="consoleForm()">
                ver formulario
            </button> -->

             <!-- <button (click)="debugForm()"> debug form</button> -->

            <mat-error *ngIf="txtErrorNoti != ''">{{txtErrorNoti}}</mat-error>
        </div>

        <button mat-icon-button (click)="matDialogRef.close(['delete',[expedientForm, resolutionForm, usersForm]])"
                aria-label="Delete"
                matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>
