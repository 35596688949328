import {  FormGroup } from '@angular/forms';


export interface IParte{
    expedient_id?:number;
    parent_id?:number;
    persona:IPersonaFisica | IPersonaMoral | IAutoridad;
    caracter:string;
  }
  
  export interface IPersonaFisica{
    id:number;
    fullname:string;
    name?:string;
    apaterno?:string;
    amaterno?:string;
    tipo:string;
    domicilio_procesal?:string;
  
  }
  
  export interface IPersonaMoral{
  
    id:number;
    fullname:string;
    name?:string;
    apaterno?:string;
    amaterno?:string;
    tipo:string;
    domicilio_procesal?:string;
  
    
  }
  
  export interface IAutoridad{
    id:number;
    fullname:string;
    name?:string;
    apaterno?:string;
    amaterno?:string;
    tipo:string;
    domicilio_procesal:string;
  
  }
  
  export interface IParteForms{
    id:number;
    form:FormGroup;
  
  }


  export class Part{
    expedient_id:number;
    promotion_id:number;
    persona:IPersonaFisica | IPersonaMoral | IAutoridad;
    caracter:string;
    parent_id?:number;
    representantes?:Part[];


    constructor(parte){

        if (parte.persona) {

          return
        }


        this.expedient_id=parte.expedient_id;
        this.promotion_id=parte.promotion_id;
        this.caracter=parte.caracter;
        this.setPersona(parte);
        this.setRepresentantes(parte);

       console.log("part constructor debug",this);

    }
    
    setPersona(parte){

      if (parte.tipo==='persona fisica') {
        this.persona={
          id:parte.id,
          fullname:parte.fullname,
          name: parte.name,
          apaterno:parte.apaterno,
          amaterno:parte.amaterno,
          tipo:parte.tipo,
          domicilio_procesal:parte.domicilio_procesal,
        } as IPersonaFisica;
      }

      if (parte.tipo==='persona juridica') {
        this.persona={
          id:parte.id,
          fullname:parte.fullname,
          tipo:parte.tipo,
          domicilio_procesal:parte.domicilio_procesal,
        } as IPersonaMoral;
        
      }

      if (parte.tipo==='autoridad') {
        
        this.persona={
          id:parte.id,
          fullname:parte.fullname,
          tipo:parte.tipo,
          domicilio_procesal:parte.domicilio_procesal,
        } as IAutoridad;
        
      }
      

    }

    setRepresentantes(parte){
 
      if(!parte.representantes){return;}
      if(!parte.representantes.data){return;}
      if(parte.representantes.data.length===0){return;}


      const representantes=[...parte.representantes.data];

      console.log("set representantes debug",parte,representantes);

      this.representantes=representantes.map(p=>new Part(p));
     


    }

    toNewRequestData(){

      return {

   
        expedient_id:this.expedient_id || null,
        promotion_id:this.promotion_id || null,
        fullname:this.persona.fullname || null,
        name:this.persona.name || null,
        apaterno:this.persona.apaterno || null,
        amaterno:this.persona.amaterno || null,
        tipo:this.persona.tipo || null,
        caracter:this.caracter|| null,
        parent_id:this.parent_id|| null,
        domicilio_procesal:this.persona.domicilio_procesal || null,

      }

    }

    toUpdateRequestData(){

      return {

           
        fullname:this.persona.fullname || null,
        name:this.persona.name || null,
        apaterno:this.persona.apaterno || null,
        amaterno:this.persona.amaterno || null,
        tipo:this.persona.tipo || null,
        domicilio_procesal:this.persona.domicilio_procesal || null,

      }

    }

    



  }