import { Component, OnInit } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { AppConfigService } from 'app/providers/data/app-config.service';
import { Router } from '@angular/router';

@Component({
  selector: 'init-screen',
  templateUrl: './init-screen.component.html',
  styleUrls: ['./init-screen.component.scss']
})
export class InitScreenComponent implements OnInit {

  currentUser:any;
  typeEmployee:any;
  estradosUrl:string;

  constructor(private _fuseConfigService: FuseConfigService,
    private _settings: AppConfigService,
    private _router: Router,) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
          navbar   : {
              hidden: true
          },
          toolbar  : {
              hidden: true
          },
          footer   : {
              hidden: true
          },
          sidepanel: {
              hidden: true
          }
      }
    };


  }

  ngOnInit(): void {
    this.currentUser  = this._settings.getCurrentUser();

    const userCurrent = this._settings.getCurrentUser();

    this.estradosUrl= this._settings.getUrlEstrados();
    
    this.typeEmployee  = userCurrent ? userCurrent.tipo : undefined;
    
    if(this.typeEmployee !== undefined) {
      this._router.navigate(['/app/inicio']);
    }else {
        console.log('not user')
        //this._router.navigate(['/pages/auth/login']);
    }
  }

  toSignup(){
    this._router.navigate(['/pages/auth/login']);
  }

}
